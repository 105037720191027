import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store' // 添加store导入

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/hotelGoods',
    name: 'hotelGoods',
    component: () => import('@/views/hotelGoods.vue'),
    // meta: {
    //   keepAlive: true
    // }
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    component: () => import('@/views/goodsDetail.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/aboutUs.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/ProjectPlan',
    name: 'ProjectPlan',
    component: () => import('@/views/ProjectPlan.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/IndustryNews',
    name: 'IndustryNews',
    component: () => import('@/views/IndustryNews.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/ProjectCase',
    name: 'ProjectCase',
    component: () => import('@/views/ProjectCase.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/ProjectCaseDetail',
    name: 'ProjectCaseDetail',
    component: () => import('@/views/projectCaseDetail.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/ExhibitionHall',
    name: 'ExhibitionHall',
    component: () => import('@/views/ExhibitionHall.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('@/views/newsDetail.vue'),
    meta: {
      keepAlive: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 在路由导航守卫中临时隐藏滚动条
router.beforeEach((to, from, next) => {
  document.body.style.overflow = 'hidden'; // 隐藏滚动条
  window.scrollTo(0, 0);
  
  // 记录路由历史，使用命名空间
  store.dispatch('history/recordRoute', to);
  
  next();
});

router.afterEach(() => {
  document.body.style.overflow = ''; // 恢复滚动条
});
export default router