import Vue from 'vue'
import Vuex from 'vuex'
import history from './modules/history'
import seo from './modules/seo'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navId: undefined,
    categoriesList: {},
    navList: [],
    selectedNavId: undefined,
  },
  getters: {},
  mutations: {
    setSelectedNav(state, id) {
      state.selectedNavId = id
    },
    SET_NAVLIST(state, navList) {
      state.navList = navList
    },
    SET_ID(state, navId) {
      state.navId = navId
    },
    SET_CATEGORIE(state, categoriesList) {
      state.categoriesList = categoriesList
    },
  },
  actions: {
    selectNav({ commit }, id) {
      commit('setSelectedNav', id)
    }
  },
  modules: {
    history,
    seo
  }
})