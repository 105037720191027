<template>
  <div class="carousel-container">
    <div class="carousel" ref="carousel" @transitionend="onTransitionEnd">
      <div
        class="slide"
        v-for="(image, index) in images"
        :key="index"
        :class="{ active: currentIndex === index }"
        @click="goDetail(image)"
      >
        <img :src="$urlImg(image.imgUrl)" alt="Slide Image" />
      </div>
      <div class="img-mask">
        <div class="img-mask-tlt">{{ content }}</div>
        <div class="img-mask-cont">{{ contentMask }}</div>
      </div>
    </div>
    <img
      class="carousel-button prev"
      @click="prevSlide"
      src="../assets/image/home/left.png"
      alt=""
    />
    <img
      class="carousel-button next"
      @click="nextSlide"
      src="../assets/image/home/right.png"
      alt=""
    />
  </div>
</template>
<script>
import { bannerInfo } from '@/api/index'
export default {
  data() {
    return {
      content: undefined,
      contentMask: undefined,
      images: [],
      currentIndex: undefined,
      isTransitioning: false,
      newExternalLink: ''
    }
  },
  created() {
    this.bannerInfo()
  },
  methods: {
    goDetail(image) {
      console.log('item.externalLink', image, this.newExternalLink)
      if (this.newExternalLink) {
        // console.log('item.externalLink', image.externalLink)
        window.open(this.newExternalLink, '_blank', 'noopener,noreferrer')
      } else {
        return
      }
    },
    bannerInfo() {
      bannerInfo({
        bCode: 'INDEX_BANNER',
        language: localStorage.getItem('lang')
      }).then((res) => {
        if (res.code == 200) {
          console.log(res)
          this.images = res.data
          // console.log('this.images111', this.images)
          this.content = this.images[0]?.title
          this.contentMask = this.images[0]?.content
          this.newExternalLink = this.images[0]?.externalLink
        }
      })
    },
    onTransitionEnd(event) {
      if (event.target.classList.contains('slide')) {
        this.isTransitioning = false
      }
    },
    prevSlide() {
      this.stopAutoPlay()
      if (this.isTransitioning) return
      this.isTransitioning = true
      // 移除当前活动图片的 active 类
      const currentSlide = this.$refs.carousel.querySelector('.active')
      if (currentSlide) {
        currentSlide.classList.remove('active')
      }
      // 更新当前索引
      this.currentIndex =
        (this.currentIndex - 1 + this.images.length) % this.images.length
      // 确保新的活动图片添加 active 类
      this.$nextTick(() => {
        const newSlide = this.$refs.carousel.querySelector(
          `.slide:nth-child(${this.currentIndex + 1})`
        )
        this.content = this.images[this.currentIndex]?.title
        this.contentMask = this.images[this.currentIndex]?.content
        this.newExternalLink = this.images[this.currentIndex]?.externalLink
        if (newSlide) {
          newSlide.offsetWidth
          newSlide.classList.add('active')
          this.startAutoPlay()
        }
      })
    },
    nextSlide() {
      this.stopAutoPlay()
      if (this.isTransitioning) return
      this.isTransitioning = true
      // 移除当前活动图片的 active 类
      const currentSlide = this.$refs.carousel.querySelector('.active')

      if (currentSlide) {
        currentSlide.classList.remove('active')
      }
      // 更新当前索引
      this.currentIndex = (this.currentIndex + 1) % this.images.length
      // 确保新的活动图片添加 active 类
      this.$nextTick(() => {
        const newSlide = this.$refs.carousel.querySelector(
          `.slide:nth-child(${this.currentIndex + 1})`
        )
        this.content = this.images[this.currentIndex]?.title
        this.contentMask = this.images[this.currentIndex]?.content
        this.newExternalLink = this.images[this.currentIndex]?.externalLink
        // console.log(
        //   'this.images[this.currentIndex].',
        //   this.images[this.currentIndex].externalLink
        // )
        if (newSlide) {
          // // 强制浏览器重排
          newSlide.offsetWidth
          // 添加 active 类
          newSlide.classList.add('active')
          this.startAutoPlay()
        }
      })
    },
    startAutoPlay() {
      this.intervalId = setInterval(() => {
        this.nextSlide()
        // this.content = this.images[this.currentIndex]?.title
        // this.contentMask = this.images[this.currentIndex]?.content
        // this.newExternalLink = this.images[this.currentIndex]?.externalLink
      }, 8000) // 每3秒切换一次
    },
    stopAutoPlay() {
      clearInterval(this.intervalId)
    }
  },
  mounted() {
    this.$el.addEventListener('transitionend', this.onTransitionEnd)

    // 使用 Vue 的 $nextTick 确保 DOM 已经更新
    this.$nextTick(() => {
      // 延迟一小段时间，让浏览器有机会渲染初始状态
      setTimeout(() => {
        this.currentIndex = 0
        const firstSlide =
          this.$refs.carousel.querySelector('.slide:first-child')

        if (firstSlide) {
          // 强制浏览器重排
          firstSlide.offsetWidth
          firstSlide.classList.add('active')
        }
      }, 100)
    })
    this.startAutoPlay()
  },
  beforeDestroy() {
    this.stopAutoPlay()
  }
}
</script>
<style scoped lang="scss">
.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  .carousel-button.prev {
    width: 36px;
    height: 36px;
    position: absolute;
    left: 100px;
    bottom: 381px;
    cursor: pointer;
  }
  .carousel-button.next {
    width: 36px;
    height: 36px;
    position: absolute;
    right: 100px;
    bottom: 381px;
    cursor: pointer;
  }
}

.carousel {
  position: relative;
  width: 100%;
  height: 100%;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: scale(1);
}
.img-mask {
  width: 591px;
  height: 256px;
  background: rgba(15, 59, 52, 0.8);
  position: absolute;
  bottom: 20px;
  left: 237px;
  padding: 56px 26px;
  border-radius: 30px;
  overflow: hidden;
  .img-mask-tlt {
    width: 100%;
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    font-size: 40px;
    color: #ffffff;
    line-height: 40px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .img-mask-cont {
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    font-size: 25px;
    color: #ffffff;
    line-height: 45px;
    text-align: left;
    font-style: normal;
    text-transform: none;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.slide.active {
  opacity: 1;
  transition: transform 8s ease-in-out, opacity 0.2s ease-in-out;
  transform: scale(1.1); /* 放大到1.2倍 */
}
.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>