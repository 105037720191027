/**
 * device.js - 移动端设备判断工具
 *
 * 使用方法：
 * 1. 在模板中直接使用：
 *    import { isMobileRef } from '@/utils/device';
 *
 *    <template>
 *      <div v-if="isMobileRef">仅在移动端显示</div>
 *    </template>
 *
 *    <script>
 *    export default {
 *      data() {
 *        return {
 *          isMobileRef
 *        }
 *      }
 *    }
 *    </script>
 *
 * 2. 在 JS 中监听状态变化：
 *    import { isMobileRef } from '@/utils/device';
 *
 *    export default {
 *      data() {
 *        return {
 *          isMobileRef
 *        }
 *      },
 *      watch: {
 *        isMobileRef(newValue) {
 *          console.log(newValue ? '切换到移动端' : '切换到桌面端');
 *        }
 *      }
 *    }
 */

import { ref } from 'vue'

// 创建响应式的移动端状态
export const isMobileRef = ref(window.innerWidth <= 750)

// 更新移动端状态
const updateMobileState = () => {
  isMobileRef.value = window.innerWidth <= 750
}

// 添加窗口大小变化监听器
window.addEventListener('resize', updateMobileState)

// 提供一个方法用于清理监听器（如果需要的话）
export const cleanupMobileListener = () => {
  window.removeEventListener('resize', updateMobileState)
}

// 为了向后兼容，保留原来的方法
export const isMobile = () => isMobileRef.value
