import {
  pageModuleseo
} from '@/api/index'

const seo = {
  namespaced: true,
  state: {
    seoData: null,
    keywords: null,
    describe: null
  },
  getters: {},
  mutations: {
    SET_SEO_DATA(state, data) {
      state.seoData = data;
      localStorage.setItem('seo', state.seoData)
    },
    SET_SEO_KEY(state, data) {
      state.keywords = data;
      localStorage.setItem('seokey', state.keywords)
    },
  },
  actions: {
    async fetchSeoData({
      commit
    }) {
      // console.log('fetchSeoData6666')
      try {
        const response = await pageModuleseo({
          language: localStorage.getItem('lang'),
        });
        if (response.code === 200) {
          // console.log('response.data',response.data)
          commit('SET_SEO_DATA', response.data);
          commit('SET_SEO_KEY', response.data.keywords);
          // localStorage.setItem('keywords', response.data.keywords)
          localStorage.setItem('describe', response.data.describe)
        }
      } catch (error) {
        console.error('Error fetching SEO data:', error);
      }
    },
  },
};

export default seo;