<template>
  <div class="home">
    <SwiperComponent></SwiperComponent>
    <!-- 轮播图 -->
    <!-- <div class="banners element" :ref="setItemRef">
      <el-carousel
        class="banner"
        :interval="5000"
        arrow="always"
        @change="handleCarouselChange"
      >
        <el-carousel-item v-for="(item, index) in swiperList" :key="index">
          <div
            class="carousel-image"
            :class="{ active: activeIndex === index }"
          >
            <img
              ref="bannerHeight"
              :src="item.path"
              style="width: 100%; height: auto"
            />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div> -->
    <!-- 推荐一 -->
    <div class="hotgoods element" :ref="setItemRef">
      <div
        class="hot-tlt"
        v-if="hotInfo.childrenMap?.PAGE_PLATE_HEAD?.moduleInfoMap"
      >
        <div class="tlt">
          {{
            hotInfo.childrenMap.PAGE_PLATE_HEAD?.moduleInfoMap?.PAGE_PLATE_TITLE
              ?.message
          }}
        </div>
        <div class="tlt-mask">
          {{
            hotInfo.childrenMap.PAGE_PLATE_HEAD?.moduleInfoMap
              ?.PAGE_PLATE_DESCRIPTION?.message
          }}
        </div>
      </div>
      <div class="goods-list" v-if="hotInfo.childrenMap?.PAGE_PLATE_CONTENT">
        <div
          class="good-item"
          v-for="(item, index) in hotInfo.childrenMap?.PAGE_PLATE_CONTENT
            ?.children"
          :key="index"
          @mouseover="onMouseOver(index)"
          @mouseleave="onMouseLeave(index)"
          @click="openHotUrl(item)"
        >
          <img
            :src="
              $urlImg(item.moduleInfoMap?.PAGE_PLATE_CONFIG_CONTENT?.message)
            "
            alt=""
            :class="imgIdx == index ? 'expanded' : ''"
          />
          <div class="project">
            <div class="project-tlt">
              {{ item.moduleInfoMap?.PAGE_PLATE_TITLE?.message }}
            </div>
            <div class="project-tlt2">
              {{ item.moduleInfoMap?.PAGE_PLATE_DESCRIPTION?.message }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 关于 -->
    <div class="about-good element" :ref="setItemRef">
      <div class="hot-tlt" v-if="aboutInfo.childrenMap?.PAGE_PLATE_HEAD">
        <div class="tlt">
          {{
            aboutInfo.childrenMap?.PAGE_PLATE_HEAD?.moduleInfoMap
              ?.PAGE_PLATE_TITLE?.message
          }}
        </div>
        <div class="tlt-mask">
          {{
            aboutInfo.childrenMap?.PAGE_PLATE_HEAD?.moduleInfoMap
              ?.PAGE_PLATE_DESCRIPTION?.message
          }}
        </div>
      </div>
      <div class="about-list">
        <div
          class="about-gd"
          v-if="aboutInfo.childrenMap?.INDEX_VIDEO_CENTER_CATEGORY"
        >
          <div class="gd-tlt">
            <div class="title">
              {{
                aboutInfo.childrenMap?.INDEX_VIDEO_CENTER_CATEGORY?.childrenMap
                  ?.PAGE_PLATE_CONTENT?.moduleInfoMap?.PAGE_PLATE_CONFIG_CONTENT
                  ?.message
              }}
            </div>
            <div class="tit-mask">
              {{
                aboutInfo.childrenMap?.INDEX_VIDEO_CENTER_CATEGORY?.childrenMap
                  ?.PAGE_PLATE_HEAD?.moduleInfoMap?.PAGE_PLATE_DESCRIPTION
                  ?.message
              }}
            </div>
            <div class="zhongxin">
              {{
                aboutInfo.childrenMap?.INDEX_VIDEO_CENTER_CATEGORY?.childrenMap
                  ?.PAGE_PLATE_CONTENT?.moduleInfoMap?.PAGE_PLATE_CONFIG_CONTENT
                  ?.message
              }}
            </div>
          </div>
          <div class="gd-btn" @click="goCaseVideo(0)">
            {{ $t('home.more') }}
          </div>
        </div>
        <SimpleSwiper
          class="video-lists"
          :dataList="videoList"
          @slide-click="(item) => openVideo(item.videoUrl)"
          :slides-per-view="3"
          :base-space-between="20"
          :adaptive-space-between="true"
          :autoplay="true"
          :autoplay-delay="5000"
          :navigation="true"
          :loop="true"
          v-if="!isMobileRef"
        >
          <template v-slot:slide="{ item }">
            <div class="video-item">
              <div class="video-img">
                <img
                  :src="$urlImg(item.coverUrl)"
                  alt=""
                />
              </div>
              <div class="video-tlt">
                <div class="title-tlt">{{ item.title }}</div>
                <div class="title-detail">
                  <span> {{ $t('Viewdetails') }}</span>
                  <img src="../assets/image/home/detail.png" alt="" />
                </div>
              </div>
            </div>
          </template>
        </SimpleSwiper>
        <div class="video-lists" v-if="isMobileRef">
          <div class="video-item"
            v-for="(item, index) in videoList" :key="index"
            @click="openVideo(item.videoUrl)"
          >
            <div class="video-img">
              <img
                :src="$urlImg(item.coverUrl)"
                alt=""
              />
            </div>
            <div class="video-tlt">
              <div class="title-tlt">{{ item.title }}</div>
              <div class="title-detail">
                <span> {{ $t('Viewdetails') }}</span>
                <img src="../assets/image/home/detail.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="video-lists" ref="scrollContainer"> -->
          <!-- <div
            class="video-item"
            v-for="(item, index) in videoList"
            :key="index"
            @mouseover="onMouseOverAbout(index)"
            @mouseleave="onMouseLeaveAbout(index)"
            @mousedown="handleMouseDown"
            @touchstart="handleTouchStart"
            @click="openVideo(item.videoUrl)"
          > -->
            <!-- <div class="video-img">
              <img
                :class="aboutIdx == index ? 'expanded' : ''"
                :src="$urlImg(item.coverUrl)"
                alt=""
              />
            </div> -->
            <!-- @click.stop="goCaseVideo(0)" -->
            <!-- <div class="video-tlt">
              <div class="title-tlt">{{ item.title }}</div>
              <div class="title-detail">
                <span> {{ $t('Viewdetails') }}</span>
                <img src="../assets/image/home/detail.png" alt="" />
              </div>
            </div> -->
          <!-- </div> -->
        <!-- </div> -->
      </div>
      <div class="success">
        <div class="success-cont" v-if="successInfo.childrenMap">
          <div>
            <div class="success-tlt">
              {{
                successInfo.childrenMap?.PAGE_PLATE_HEAD?.moduleInfoMap
                  ?.PAGE_PLATE_TITLE?.message
              }}
            </div>
            <div class="tlt-masks">
              {{
                successInfo.childrenMap?.PAGE_PLATE_HEAD?.moduleInfoMap
                  ?.PAGE_PLATE_DESCRIPTION?.message
              }}
            </div>
            <!-- <div class="success-goods">
              <div
                class="category"
                v-for="(item, index) in successTabList"
                :key="index"
                :class="cateId == index ? 'category-act' : ''"
                @click="seleSuccess(item, index)"
              >
                {{ item.tagLabel }}
              </div>
            </div> -->
          </div>
          <div class="cate-btn" @click="goCaseVideo(1)">
            {{ $t('home.more') }}
          </div>
        </div>
        <div class="cate-lunbo" v-if="!isMobileRef">
          <SimpleSwiper
            class="case-lists"
            :dataList="successContList"
            @slide-click="(item) => seleCate(item.id)"
            :slides-per-view="3"
            :base-space-between="10"
            :adaptive-space-between="true"
            :autoplay="true"
            :autoplay-delay="5000"
            :navigation="true"
            :loop="true"
          >
            <template v-slot:slide="{ item }">
              <div class="case-item">
                <img :src="$urlImg(item.coverUrl)" :data-url="item.coverUrl" />
                <div class="hovers">
                  <div class="hovers-tlt">{{ item.title }}</div>
                </div>
              </div>
            </template>
          </SimpleSwiper>
        </div>
        <div class="case-lists" v-if="isMobileRef">
          <div class="case-item"
            v-for="(item, index) in successContList" :key="index"
            @click="seleCate(item.id)"
          >
            <img :src="$urlImg(item.coverUrl)" :data-url="item.coverUrl" />
            <div class="hovers">
              <div class="hovers-tlt">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <!-- <div class="cate-lunbo" v-if="successContList">
          <swiper
            :options="swiperOptions3"
            v-if="successContList.length"
          > -->
            <!-- <swiper-slide
              v-for="(slide, index) in successContList"
              :key="index"
              @click.native="seleCate(slide.id)"
            >
              <img :src="$urlImg(slide.coverUrl)" :data-url="slide.coverUrl" />
              <div class="hovers">
                <div>{{ slide.title }}</div>
              </div>
            </swiper-slide>
          </swiper> -->
          <!-- <div class="swiper-pagination" slot="pagination"></div> -->
          <!-- <div class="swiper-button-next" slot="button-next"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
        </div> -->

        <!-- <div class="cate-goods" ref="scrollContainer2">
          <div
            class="cate-good"
            v-for="(item, index) in successContList"
            :key="index"
            @mousedown="handleMouseDown2"
            @touchstart="handleTouchStart2"
            @click="seleCate(item.id)"
          >
            <img :src="$urlImg(item.coverUrl)" alt="" />
            <div class="hovers">
              <div>{{ item.title }}</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 团队人数 -->
    <div class="number-list element" :ref="setItemRef">
      <div
        class="num-item"
        v-for="(item, index) in numberInfo.moduleInfo"
        :key="index"
      >
        <div class="num">
          {{ item.currentNum }}
          <span v-if="item.remark == '篷房生产基地'">㎡</span>
          <span v-else>+</span>
        </div>
        <div class="num-tlt">
          {{ item.remark }}
        </div>
      </div>
    </div>
    <!-- 证书专利 -->
    <div class="zhengshu element" :ref="setItemRef">
      <div class="zs-banner" v-if="gongchangInfo.children">
        <img
          class="zs-ban"
          :src="
            $urlImg(gongchangInfo.children[0]?.moduleInfoMap?.US_COVER?.message)
          "
          alt=""
        />
        <div class="zs-jieshao">
          <div class="js-tlt">
            {{
              gongchangInfo.children[0]?.moduleInfoMap?.PAGE_PLATE_TITLE
                ?.message
            }}
          </div>
          <div class="js-mask">
            {{
              gongchangInfo.children[0]?.moduleInfoMap
                ?.PAGE_PLATE_CONFIG_CONTENT?.message
            }}
          </div>
          <div class="js-tlt">
            {{
              gongchangInfo.children[1]?.moduleInfoMap?.PAGE_PLATE_TITLE
                ?.message
            }}
          </div>
          <div class="js-mask">
            {{
              gongchangInfo.children[1]?.moduleInfoMap
                ?.PAGE_PLATE_CONFIG_CONTENT?.message
            }}
          </div>
          <div class="js-btn" @click="goAbout">{{ $t('home.more') }}</div>
        </div>
      </div>
      <div class="zs-lists">
        <!-- <div class="zs-tab"> -->
          <!-- <div
            class="tab"
            :class="zsId == 0 ? 'tab-act' : ''"
            @click="selePic(0)"
          >
            {{ $t('Factory') }}
          </div> -->
          <!-- <div
            class="tab"
            :class="zsId == 1 ? 'tab-act' : ''"
            @click="selePic(1)"
          >
            {{ $t('Honors') }}
          </div> -->
          <!-- <div
            class="tab"
            :class="zsId == 2 ? 'tab-act' : ''"
            @click="selePic(2)"
          >
            企业相册
          </div> -->
        <!-- </div> -->
        <!-- swiper -->

        <div class="lunbo" v-if="zsId == 0">
          <template v-if="!isMobileRef">
            <swiper
              :options="swiperOptions"
              ref="mySwiper1"
              v-if="shengchanList.length"
            >
              <swiper-slide v-for="(slide, index) in shengchanList" :key="index">
                <img :src="$urlImg(slide.message)" :data-url="slide.message" />
              </swiper-slide>
            </swiper>
            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
            <div class="swiper-button-next company-gallery-swiper-button-next" slot="button-next"></div>
            <div class="swiper-button-prev company-gallery-swiper-button-prev" slot="button-prev"></div>
          </template>
          <div class="zs-list" v-if="isMobileRef">
            <div class="zs-item" v-for="(item, index) in shengchanList" :key="index">
              <img class="zs-pic" :src="$urlImg(item.message)" :data-url="item.coverUrl" />
            </div>
          </div>
        </div>
        <!-- <div v-if="zsId == 1">
          <div class="lunbo">
            <swiper
              :options="swiperOptions2"
              ref="mySwiper2"
              v-if="shengchanList2.length"
            >
              <swiper-slide
                v-for="(slide2, index) in shengchanList2"
                :key="index"
              >
                <img
                  :src="$urlImg(slide2.message)"
                  :data-url="slide2.message"
                />
              </swiper-slide>
            </swiper>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
          </div>
        </div> -->

        <!-- <div class="zs-list" ref="scrollContainer3">
          <div
            class="zs-item"
            v-for="(item, index) in shengchanList"
            :key="index"
            @mousedown="handleMouseDown3"
            @touchstart="handleTouchStart3"
          >
            <img class="zs-pic" :src="$urlImg(item.message)" alt="" />
          </div>
        </div> -->
      </div>
    </div>
    <!-- 新闻动态 -->
    <div class="news element" :ref="setItemRef">
      <div class="new-tlt">
        {{
          newsInfo.childrenMap?.PAGE_PLATE_HEAD?.moduleInfoMap?.PAGE_PLATE_TITLE
            ?.message
        }}
      </div>
      <div class="new-mask">
        {{
          newsInfo.childrenMap?.PAGE_PLATE_HEAD?.moduleInfoMap
            ?.PAGE_PLATE_DESCRIPTION?.message
        }}
      </div>
      <SimpleSwiper
        class="news-list"
        :dataList="newsList"
        @slide-click="(item) => goNewDetail(item.id)"
        :slides-per-view="3"
        :base-space-between="50"
        :adaptive-space-between="true"
        :autoplay="true"
        v-if="!isMobileRef"
      >
        <template v-slot:slide="{ item }">
          <div class="new-item">
            <img class="new-pic" :src="$urlImg(item.coverUrl)" :data-url="item.coverUrl" />
            <div class="new-title">
              <div class="new-tlt2">{{ item.title }}</div>
              <img
                class="jiantou"
                src="../assets/image/home/jiantou.png"
                alt=""
              />
            </div>
          </div>
        </template>
      </SimpleSwiper>
      <div class="news-list" v-if="isMobileRef">
        <div class="new-item" v-for="(item, index) in newsList" :key="index" @click="goNewDetail(item.id)">
          <img class="new-pic" :src="$urlImg(item.coverUrl)" :data-url="item.coverUrl" />
          <div class="new-title">
            <div class="new-tlt2">{{ item.title }}</div>
            <img
              class="jiantou"
              src="../assets/image/home/jiantou.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 视频弹出框 -->
    <el-dialog
      @close="closeVideo"
      title=""
      :visible.sync="dialogVisible"
      width="45%"
    >
      <video
        ref="videoPlayer"
        autoplay
        controls
        @play="onPlay"
        @pause="onPause"
        style="width: 100%; height: 80%"
        :key="videoKey"
      >
        <source :src="videoListPath" type="video/mp4" />
      </video>
    </el-dialog>
  </div>
</template>
<script>
import {
  moduleInfo,
  pageModuleModuleInfo,
  recommend,
  projectRecommend
} from '@/api/index'
import SwiperComponent from '../components/SwiperComponent.vue'
import SimpleSwiper from '../components/SimpleSwiper.vue'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { isMobileRef } from '@/utils/device'
export default {
  name: 'HomeView',
  data() {
    return {
      isMobileRef,
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          //自动开始
          delay: 3000, //时间间隔
          disableOnInteraction: false //*手动操作轮播图后不会暂停*
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        navigation: {
          nextEl: '.company-gallery-swiper-button-next',
          prevEl: '.company-gallery-swiper-button-prev'
        },
        slidesPerView: 4, // 一组三个
        spaceBetween: 10, // 间隔
        loop: true // 循环模式
      },
      videoListPath: '',
      dialogVisible: false,
      videoKey: 0,
      swiperList: [],
      imgIdx: undefined,
      aboutIdx: undefined,
      newIdx: undefined,
      zsId: 0,
      cateId: 0,
      numList: [
        {
          title: '团队人数',
          num: 300
        },
        {
          title: '篷房生产基地',
          num: 3000
        },
        {
          title: '篷房出口国家',
          num: 1000
        },
        {
          title: '合作客户',
          num: 25000
        },
        {
          title: '公司成立',
          num: 10
        }
      ],
      intervals: [],
      observer: null,
      elementRefs: [],
      // 超出滑动
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
      // 成功案例
      isDragging2: false,
      startX2: 0,
      scrollLeft2: 0,
      // 生产基地
      isDragging3: false,
      startX3: 0,
      scrollLeft3: 0,
      homeInfo: [
        {
          children: [
            {
              moduleInfo: [{ message: '' }, { message: '' }]
            },
            {
              moduleInfo: [{ message: '' }, { message: '' }]
            }
          ]
        },
        {
          children: [
            // {
            //   moduleInfo: [{ message: '' }, { message: '' }]
            // },
            // {
            //   moduleInfo: [{ message: '' }, { message: '' }, { message: '' }]
            // },
            // {
            //   moduleInfo: [{ message: '' }, { message: '' }, { message: '' }]
            // },
            // {
            //   moduleInfo: [{ message: '' }, { message: '' }, { message: '' }]
            // },
            // {
            //   moduleInfo: [{ message: '' }, { message: '' }, { message: '' }]
            // },
            // {
            //   moduleInfo: [{ message: '' }, { message: '' }, { message: '' }]
            // }
          ]
        }
      ],
      // 格拉丹野奢度假屋
      hotInfo: {},
      //关于格拉丹
      aboutInfo: {},
      // 成功案例
      successInfo: {},
      // 新闻动态
      newsInfo: {},
      // 生产基地
      shengchanList: [],
      shengchanList2: [],
      // 公司统计
      numberInfo: {},
      // 新闻动态
      tablePage: {
        language: localStorage.getItem('lang'),
        catgoryCode: 'industry_news'
      },
      newsList: [],
      // 获取视频
      tablePageVideo: {
        language: localStorage.getItem('lang'),
        catgoryCode: 'case_videos'
      },
      videoList: [],
      // 成功案例导航
      successTabList: [],
      successContList: [],
      // 工厂环境
      gongchangInfo: {}
    }
  },

  components: {
    SwiperComponent,
    swiper,
    swiperSlide,
    SimpleSwiper
  },
  computed: {},
  mounted() {
    this.pageModuleModuleInfo()
    this.initObserver()
    // this.$nextTick(() => {
    //   if (this.$refs.mySwiper && this.$refs.mySwiper.swiper) {
    //     console.log('Swiper initialized:', this.$refs.mySwiper.swiper)
    //   } else {
    //     console.error('Swiper initialization failed')
    //   }
    // })
    // this.startIncrement()
  },
  created() {
    this.moduleInfo()
    // this.pageModuleModuleInfo()
    this.pageModuleModuleInfo2()
    // 获取新闻
    this.recommendNew()
    //获取视频
    this.recommendVideo()
    // 获取成功案例标题
    this.projectRecommend()
  },
  methods: {
    openHotUrl(item) {
      // console.log('111111', item)
      if (item.moduleInfoMap?.PAGE_PLATE_CONFIG_CONTENT?.externalLink !== '') {
        window.open(
          item.moduleInfoMap?.PAGE_PLATE_CONFIG_CONTENT?.externalLink,
          '_blank',
          'noopener,noreferrer'
        )
      }
    },
    seleSuccess(item, index) {
      this.cateId = index
      const successContListNew = this.successTabList.find((ele) => {
        return ele.tagCode == item.tagCode
      })
      if (successContListNew.articles) {
        this.successContList = successContListNew.articles
      }
      // console.log('successContListNew', successContListNew)
    },
    projectRecommend() {
      projectRecommend({
        language: localStorage.getItem('lang')
      }).then((res) => {
        if (res.code == 200) {
          // console.log(res)
          // this.successTabList = res.data
          // if (this.successTabList[0]?.articles) {
          // this.successContList = this.successTabList[0].articles
          // }
          this.successContList = res.data
        }
      })
    },
    recommendNew() {
      recommend(this.tablePage).then((res) => {
        if (res.code == 200) {
          this.newsList = res.data
        }
      })
    },
    recommendVideo() {
      recommend(this.tablePageVideo).then((res) => {
        if (res.code == 200) {
          // console.log(res)
          this.videoList = res.data
        }
      })
    },
    seleCate(id) {
      console.log('idididid',id)
      this.$router.push({
        path: '/projectCaseDetail',
        query: { id: id }
      })
    },
    onPlay() {
      // this.$el.querySelector('video').play()
    },
    onPause() {
      // this.$el.querySelector('video').pause()
    },
    openVideo(url) {
      if (!url) {
        return
      }
      // 如果是完整的URL（以http或https开头）
      if (url.startsWith('http://') || url.startsWith('https://')) {
        this.videoListPath = url
        this.dialogVisible = true
        this.videoKey += 1 // 更新 key 强制刷新组件
        this.$nextTick(() => {
          // 确保视频元素存在并且已经加载了新的资源
          if (this.$refs.videoPlayer) {
            this.$refs.videoPlayer.load() // 重新加载视频资源
            this.$refs.videoPlayer.play().catch((error) => {
              console.error('自动播放失败:', error)
            })
          }
        })
      } else {
        // console.log('5555555664')
        const baseUrl = 'https://gl.tents-hotel.com/prod-api'
        this.videoListPath = `${baseUrl}${url.startsWith('/') ? '' : '/'}${url}`
        // this.videoListPath = url
        this.dialogVisible = true
        this.videoKey += 1 // 更新 key 强制刷新组件
        this.$nextTick(() => {
          // 确保视频元素存在并且已经加载了新的资源
          if (this.$refs.videoPlayer) {
            this.$refs.videoPlayer.load() // 重新加载视频资源
            this.$refs.videoPlayer.play().catch((error) => {
              console.error('自动播放失败:', error)
            })
          }
        })
      }
    },
    closeVideo() {
      this.videoListPath = ''
      this.$refs.videoPlayer.load()
      this.dialogVisible = false
    },
    goCaseVideo(e) {
      // console.log('this.$store.state.navList', this.$store.state.navList)
      const tabId = this.$store.state.navList.find((ele) => {
        return ele.path == '/ProjectCase'
      })
      // console.log('this.$store.state.tabId', tabId)
      localStorage.setItem('navIdx', tabId.id)
      this.$router.push(
        {
          path: '/ProjectCase',
          query: { type: e }
        },
        () => {}
      )
    },
    // 生产基地
    handleMouseDown3(event) {
      this.isDragging3 = true
      this.startX3 = event.pageX - this.$refs.scrollContainer3.offsetLeft
      this.scrollLeft3 = this.$refs.scrollContainer3.scrollLeft
      document.addEventListener('mousemove', this.handleMouseMove3)
      document.addEventListener('mouseup', this.handleMouseUp3)
    },
    handleMouseMove3(event) {
      if (!this.isDragging3) return
      event.preventDefault()
      const x = event.pageX - this.$refs.scrollContainer3.offsetLeft
      const walk = (x - this.startX3) * 2 // 滑动速度
      this.$refs.scrollContainer3.scrollLeft = this.scrollLeft3 - walk
    },
    handleMouseUp3() {
      this.isDragging = false
      document.removeEventListener('mousemove', this.handleMouseMove3)
      document.removeEventListener('mouseup', this.handleMouseUp3)
    },
    handleTouchStart3(event) {
      this.isDragging3 = true
      this.startX3 =
        event.touches[0].pageX - this.$refs.scrollContainer3.offsetLeft
      this.scrollLeft3 = this.$refs.scrollContainer3.scrollLeft
      document.addEventListener('touchmove', this.handleTouchMove3)
      document.addEventListener('touchend', this.handleTouchEnd3)
    },
    handleTouchMove3(event) {
      if (!this.isDragging3) return
      event.preventDefault()
      const x = event.touches[0].pageX - this.$refs.scrollContainer3.offsetLeft
      const walk = (x - this.startX3) * 2 // 滑动速度
      this.$refs.scrollContainer3.scrollLeft = this.scrollLeft3 - walk
    },
    handleTouchEnd3() {
      this.isDragging3 = false
      document.removeEventListener('touchmove', this.handleTouchMove3)
      document.removeEventListener('touchend', this.handleTouchEnd3)
    },
    selePic(e) {
      this.zsId = e
      // this.$refs.scrollContainer3.scrollLeft = 0
      this.pageModuleModuleInfo()
    },
    pageModuleModuleInfo() {
      pageModuleModuleInfo({
        language: localStorage.getItem('lang'),
        moduleCode: 'PAGE_ABOUNT_US'
      }).then((res) => {
        if (res.code == 200) {
          this.gongchangInfo = res.data.find((ele) => {
            return ele.moduleCode == 'US_FACTORY'
          })
          // console.log('this.gongchangInfo', this.gongchangInfo)
          // let isZizhishow = {}

          const isZizhishow1 = res.data.find((ele) => {
            return ele.moduleCode == 'US_PRODUCTION_BASE'
          })
          this.shengchanList = isZizhishow1.moduleInfo

          const isZizhishow2 = res.data.find((ele) => {
            return ele.moduleCode == 'US_HONOR'
          })
          this.shengchanList2 = isZizhishow2.moduleInfo
          // console.log('this.shengchanList2', this.shengchanList2)

          // console.log('this.shengchanList2', this.shengchanList2)
          // isZizhishow = res.data.find((ele) => {
          //   return ele.moduleCode == 'US_PICTURE_ALBUM'
          // })
          // this.shengchanList = isZizhishow.moduleInfo

          // this.getSwiper()
          // console.log('this.shengchanList', this.shengchanList)
        }
      })
    },

    pageModuleModuleInfo2() {
      pageModuleModuleInfo({
        language: localStorage.getItem('lang'),
        moduleCode: 'PAGE_ABOUNT_US'
      }).then((res) => {
        if (res.code == 200) {
          this.numberInfo = res.data.find((ele) => {
            return ele.moduleCode == 'US_STATISTICS'
          })
          this.startIncrement()
          // console.log('this.shengchanList', this.shengchanList)
        }
      })
    },
    moduleInfo() {
      moduleInfo({
        language: localStorage.getItem('lang'),
        moduleCode: 'PAGE_INDEX'
      }).then((res) => {
        if (res.code == 200) {
          this.homeInfo = res.data
          this.hotInfo = this.homeInfo.find((ele) => {
            return ele.moduleCode == 'INDEX_PRODUCT_CENTER'
          })
          this.aboutInfo = this.homeInfo.find((ele) => {
            return ele.moduleCode == 'INDEX_VIDEO_CENTER'
          })
          this.successInfo = this.homeInfo.find((ele) => {
            return ele.moduleCode == 'INDEX_CUSTOMER_CASE'
          })
          this.newsInfo = this.homeInfo.find((ele) => {
            return ele.moduleCode == 'INDEX_NEWS'
          })
          // let _hotSubInfo = {}
          // this.hotInfo.children.forEach((item) => {
          //   _hotSubInfo[item.moduleCode] = item
          // })
          // this.hotInfo.subInfo = _hotSubInfo
          // this.hotInfo.subInfo['PAGE_PLATE_CONTENT']['TITLE']
          // getMapInfo('PAGE_PLATE_CONTENT', 'TITLE')
          // console.log('this.hotInfo111', this.hotInfo)
          // console.log(
          //   this.getMapInfo(this.hotInfo.subInfo, 'PAGE_PLATE_CONTENT', 'bh')
          // )
        }
      })
    },
    getMapInfo(map, ...keys) {
      console.log(map)
      let _value = map
      for (let i = 0; i < keys.length; i++) {
        _value = _value[keys[i]]
        if (!_value) {
          return null
        }
      }
      return _value
    },
    goAbout() {
      this.$router.push(
        {
          path: '/aboutUs'
        },
        () => {}
      )
    },
    goNewDetail(id) {
      this.$router.push(
        {
          path: '/newsDetail',
          query: { id: id }
        },
        () => {}
      )
    },
    // 成功案例
    handleMouseDown2(event) {
      this.isDragging2 = true
      this.startX2 = event.pageX - this.$refs.scrollContainer2.offsetLeft
      this.scrollLeft2 = this.$refs.scrollContainer2.scrollLeft
      document.addEventListener('mousemove', this.handleMouseMove2)
      document.addEventListener('mouseup', this.handleMouseUp2)
    },
    handleMouseMove2(event) {
      if (!this.isDragging2) return
      event.preventDefault()
      const x = event.pageX - this.$refs.scrollContainer2.offsetLeft
      const walk = (x - this.startX2) * 2 // 滑动速度
      this.$refs.scrollContainer2.scrollLeft = this.scrollLeft2 - walk
    },
    handleMouseUp2() {
      this.isDragging = false
      document.removeEventListener('mousemove', this.handleMouseMove2)
      document.removeEventListener('mouseup', this.handleMouseUp2)
    },
    handleTouchStart2(event) {
      this.isDragging2 = true
      this.startX2 =
        event.touches[0].pageX - this.$refs.scrollContainer2.offsetLeft
      this.scrollLeft2 = this.$refs.scrollContainer2.scrollLeft
      document.addEventListener('touchmove', this.handleTouchMove2)
      document.addEventListener('touchend', this.handleTouchEnd2)
    },
    handleTouchMove2(event) {
      if (!this.isDragging2) return
      event.preventDefault()
      const x = event.touches[0].pageX - this.$refs.scrollContainer2.offsetLeft
      const walk = (x - this.startX2) * 2 // 滑动速度
      this.$refs.scrollContainer2.scrollLeft = this.scrollLeft2 - walk
    },
    handleTouchEnd2() {
      this.isDragging2 = false
      document.removeEventListener('touchmove', this.handleTouchMove2)
      document.removeEventListener('touchend', this.handleTouchEnd2)
    },
    // 视频中心
    handleMouseDown(event) {
      this.isDragging = true
      this.startX = event.pageX - this.$refs.scrollContainer.offsetLeft
      this.scrollLeft = this.$refs.scrollContainer.scrollLeft
      document.addEventListener('mousemove', this.handleMouseMove)
      document.addEventListener('mouseup', this.handleMouseUp)
    },
    handleMouseMove(event) {
      if (!this.isDragging) return
      event.preventDefault()
      const x = event.pageX - this.$refs.scrollContainer.offsetLeft
      const walk = (x - this.startX) * 2 // 滑动速度
      this.$refs.scrollContainer.scrollLeft = this.scrollLeft - walk
    },
    handleMouseUp() {
      this.isDragging = false
      document.removeEventListener('mousemove', this.handleMouseMove)
      document.removeEventListener('mouseup', this.handleMouseUp)
    },
    handleTouchStart(event) {
      this.isDragging = true
      this.startX =
        event.touches[0].pageX - this.$refs.scrollContainer.offsetLeft
      this.scrollLeft = this.$refs.scrollContainer.scrollLeft
      document.addEventListener('touchmove', this.handleTouchMove)
      document.addEventListener('touchend', this.handleTouchEnd)
    },
    handleTouchMove(event) {
      if (!this.isDragging) return
      event.preventDefault()
      const x = event.touches[0].pageX - this.$refs.scrollContainer.offsetLeft
      const walk = (x - this.startX) * 2 // 滑动速度
      this.$refs.scrollContainer.scrollLeft = this.scrollLeft - walk
    },
    handleTouchEnd() {
      this.isDragging = false
      document.removeEventListener('touchmove', this.handleTouchMove)
      document.removeEventListener('touchend', this.handleTouchEnd)
    },
    startIncrement() {
      // console.log('this.numberInfo.moduleInfo', this.numberInfo.moduleInfo)
      this.numberInfo.moduleInfo.forEach((item, index) => {
        let currentValue = 0
        const targetValue = Number(item.message)
        const increment = Math.ceil(targetValue / 100) // 每次增加的值
        const intervalId = setInterval(() => {
          if (currentValue < targetValue) {
            currentValue += increment
            this.$set(
              this.numberInfo.moduleInfo[index],
              'currentNum',
              currentValue
            )
          } else {
            this.$set(
              this.numberInfo.moduleInfo[index],
              'currentNum',
              targetValue
            )
            clearInterval(intervalId)
          }
        }, 100) // 每 10 毫秒增加一次
        this.intervals.push(intervalId)
      })
    },
    onMouseOverNew(index) {
      this.newIdx = index
    },
    onMouseLeaveNew() {
      this.newIdx = undefined
    },
    onMouseOver(index) {
      this.imgIdx = index
    },
    onMouseLeave() {
      this.imgIdx = undefined
    },
    onMouseOverAbout(index) {
      this.aboutIdx = index
    },
    onMouseLeaveAbout() {
      this.aboutIdx = undefined
    },
    setItemRef(el) {
      if (el) {
        this.elementRefs.push(el)
        // console.log('el11111111', el)
      }
    },
    initObserver() {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible')
            }
          })
        },
        {
          threshold: 0.1 // 当元素 10% 进入视口时触发
        }
      )
      this.elementRefs.forEach((el) => {
        this.observer.observe(el)
      })
    }
  },
  watch: {
    shengchanList: {
      handler(newVal) {
        if (newVal.length > 0 && this.$refs.mySwiper1) {
          if (this.zsId == 0) {
            this.$nextTick(() => {
              const swiperInstance = this.$refs.mySwiper1.swiper
              if (swiperInstance) {
                // console.log('Swiper 1 initialized:', swiperInstance)
              } else {
                // console.error('Failed to initialize Swiper 1')
              }
            })
          } else {
            this.$nextTick(() => {
              const swiperInstance = this.$refs.mySwiper2.swiper
              if (swiperInstance) {
                console.log('Swiper 1 initialized:', swiperInstance)
              } else {
                console.error('Failed to initialize Swiper 1')
              }
            })
          }
        }
      },
      deep: true,
      immediate: true
    }
    // shengchanList2: {
    //   handler(newVal) {
    //     if (newVal.length > 0 && this.$refs.mySwiper2 && this.zsId == 1) {
    //       this.$nextTick(() => {
    //         const swiperInstance = this.$refs.mySwiper2.swiper
    //         if (swiperInstance) {
    //           console.log('Swiper 2 initialized:', swiperInstance)
    //         } else {
    //           console.error('Failed to initialize Swiper 2')
    //         }
    //       })
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  beforeDestroy() {
    // 清除所有定时器
    this.intervals.forEach((intervalId) => clearInterval(intervalId))
  }
}
</script>

<style scoped lang="scss">
@import '../assets/home.css';
.home {
  background: #ffffff;
  // padding-bottom: 60px;
}
.el-dialog__wrapper {
  ::v-deep .el-dialog__body {
    padding: 0 !important;
    padding-top: 10px !important;
  }
}
.lunbo {
  width: 100%;
  height: 306px;
  position: relative;
  // margin-top: 43px;
  ::v-deep .swiper-container {
    .swiper-slide {
      width: 326px;
      height: 306px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .company-gallery-swiper-button-next {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 0;
    top: 49%;
    background-image: url('../assets/image/next.png');
    background-size: 100%;
  }
  .company-gallery-swiper-button-prev {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 0;
    top: 49%;
    background-image: url('../assets/image/prev.png');
    background-size: 100%;
  }
}
.element {
  width: 100%;
  opacity: 0;
  transform: translateY(80px);
  transition: all 2s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.carousel-container {
  position: relative;
  width: 100%;
  height: 798px;
  overflow: hidden;
  text-align: center;
  .carousel {
    position: relative;
    width: 100%;
    height: 100%;
    .slide {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: transform 1s ease-in-out, opacity 1s ease-in-out;
      transform: scale(1);
      .active {
        opacity: 1;
        transform: scale(1.2); /* 放大到1.2倍 */
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.element.visible {
  opacity: 1;
  transform: translateY(0);
}
.banners {
  width: 100%;
  height: 798px;
  overflow: hidden;
  position: relative;
  .banner.el-carousel {
    height: 798px !important;
    .el-carousel__container {
      height: 798px !important;
      position: relative !important;
      .el-carousel__item {
        height: 798px !important;
        background-size: 100% 100%;
        background-blend-mode: multiply;
      }
    }
  }
}
.hotgoods {
  width: 100%;
  height: auto;
  padding: 0 238px;
  margin-top: 70px;
  overflow: hidden;
  padding-bottom: 95px;
  .hot-tlt {
    width: auto;
    margin: 0 auto;
    .tlt {
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
    .tlt-mask {
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 16px;
      color: #3d485d;
      line-height: 19px;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin-top: 10px;
    }
  }
  .goods-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
    .good-item {
      width: 355px;
      height: 332px;
      margin-bottom: 14px;
      border-radius: 15px;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
      }
      .project {
        width: 100%;
        height: 82px;
        background-image: url('../assets/image/home/small.png');
        background-size: 100% 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 8px 20px;
        .project-tlt {
          font-family: ABeeZee, ABeeZee;
          font-weight: 400;
          font-size: 28px;
          color: #ffffff;
          line-height: 33px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
        .project-tlt2 {
          font-family: ABeeZee, ABeeZee;
          font-weight: 400;
          font-size: 18px;
          color: #ffffff;
          line-height: 21px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }
      .expanded {
        transform: scale(1.1);
      }
    }
    .good-item:nth-child(3n) {
      width: 690px;
      height: 332px;
    }
  }
}
.about-good {
  width: 100%;
  height: 1436px;
  background: #f5f7fa;
  padding: 0 108px 152px 238px;
  overflow: hidden;
  .hot-tlt {
    width: auto;
    margin: 0 auto;
    margin-top: 70px;
    .tlt {
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
    .tlt-mask {
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 16px;
      color: #3d485d;
      line-height: 19px;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin-top: 10px;
    }
  }
  .about-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 38px;
    height: 420px;
    .about-gd {
      width: auto;
      height: 420px;
      padding-top: 17px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .gd-tlt {
        .title {
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: 400;
          font-size: 30px;
          color: #000000;
          line-height: 35px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
        .tit-mask {
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: 400;
          font-size: 16px;
          color: #666666;
          line-height: 19px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-top: 7px;
        }
        .zhongxin {
          min-width: 70px;
          width: fit-content;
          height: 26px;
          border-bottom: 2px solid #0f3b34;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: 400;
          font-size: 16px;
          color: #0f3b34;
          line-height: 19px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-left: 17px;
          margin-top: 22px;
        }
      }
      .gd-btn {
        width: 130px;
        height: 44px;
        background: #0f3b34;
        border-radius: 90px 90px 90px 90px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 44px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        cursor: pointer;
      }
    }
    .video-lists {
      width: 1348px;
      height: 420px;
      display: flex;
      overflow: hidden;
      // overflow-x: scroll;
      user-select: none; /* 禁用文本选择 */
      touch-action: pan-y; /* 禁用图片拖动 */

      .video-item {
        width:100%;
        height: 100%;
        // width: 436px;
        // height: 420px;
        background: #ffffff;
        border-radius: 15px 15px 0px 0px;
        margin-right: 20px;
        flex-shrink: 0;
        // overflow: hidden;
        cursor: pointer;

        .video-img {
          width: 100%;
          height: 336px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            transition: all 0.3s ease;
            user-select: none; /* 禁用图片选择 */
            -webkit-user-drag: none; /* 禁用图片拖动 */
            // user-drag: none;

            &:hover {
              transform: scale(1.1);
            }
          }
        }

        .video-tlt {
          width: 100%;
          padding: 0 15px 0 9px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 24px;
          .title-tlt {
            font-family: Microsoft YaHei UI, Microsoft YaHei UI;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
            line-height: 19px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            // user-select: none; /* 禁用文本选择 */
          }
          .title-detail {
            display: flex;
            align-items: center;
            span {
              font-family: Microsoft YaHei UI, Microsoft YaHei UI;
              font-weight: 400;
              font-size: 14px;
              color: #0f3b34;
              line-height: 16px;
              text-align: left;
              font-style: normal;
              text-transform: none;
              // user-select: none; /* 禁用文本选择 */
            }
            img {
              width: 20px;
              height: 20px;
              margin-left: 8px;
            }
          }
        }
      }
      // .video-item:nth-child(3n) {
      //   margin-right: 0;
      // }
    }
  }
  .success {
    width: 100%;
    height: 548px;
    margin-top: 146px;
    position: relative;
    .success-cont {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 366px;
      height: 548px;
      background: #0f3b34;
      padding: 77px 24px 60px 24px;
      .success-tlt {
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 30px;
        color: #ffffff;
        line-height: 35px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .tlt-masks {
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 7px;
      }
      .success-goods {
        margin-top: 22px;
        margin-left: 17px;
        .category {
          width: fit-content;
          height: 26px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          line-height: 19px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 40px;
          cursor: pointer;
        }
        .category-act {
          border-bottom: 2px solid #ffffff;
        }
      }
      .cate-btn {
        width: 130px;
        height: 44px;
        background: #ffffff;
        border-radius: 90px 90px 90px 90px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 16px;
        color: #0f3b34;
        line-height: 44px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-top: 60px;
        cursor: pointer;
      }
    }
    .cate-goods {
      width: 1348px;
      height: 548px;
      position: absolute;
      top: 40px;
      right: 0;
      display: flex;
      // justify-content: space-between;
      overflow: hidden;
      .cate-good {
        width: 436px;
        height: 420px;
        border-radius: 15px;
        position: relative;
        // background: #ccc;
        user-select: none; /* 禁用文本选择 */
        touch-action: pan-y; /* 禁用图片拖动 */
        cursor: pointer;
        flex-shrink: 0;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 15px;
          user-select: none; /* 禁用图片选择 */
          -webkit-user-drag: none; /* 禁用图片拖动 */
        }
        .hovers {
          width: 100%;
          height: 100%;
          background: rgba(28, 43, 40, 0.6);
          border-radius: 15px 15px 15px 15px;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          div {
            max-width: 348px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI;
            font-weight: bold;
            font-size: 48px;
            color: #ffffff;
            line-height: 56px;
            text-align: center;
            font-style: normal;
            text-transform: none;
            margin: 0 auto;
            margin-top: 130px;
            user-select: none; /* 禁用文本选择 */
          }
        }
      }
      .cate-good:hover {
        .hovers {
          opacity: 1;
        }
      }
    }
    .cate-lunbo {
      width: 1348px;
      height: 420px;
      position: relative;
      position: absolute;
      top: 40px;
      right: 0;

      .case-lists {
        width: 100%;
        height: 100%;
      }

      // margin-top: 43px;
      .case-item {
        width: 100%;
        height: 420px;
        border-radius: 15px;
        position: relative;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          border-radius: 15px;
        }

        .hovers {
          width: 100%;
          height: 100%;
          background: rgba(28, 43, 40, 0.6);
          border-radius: 15px 15px 15px 15px;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          display: flex;
          align-items: center;
          transition: opacity 0.5s ease;

          .hovers-tlt {
            max-width: 348px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI;
            font-weight: bold;
            font-size: 48px;
            color: #ffffff;
            line-height: 56px;
            text-align: center;
            font-style: normal;
            text-transform: none;
            margin: 0 auto;
            // margin-top: 130px;

          }
        }
      }
      .swiper-slide:hover {
        .hovers {
          opacity: 1;
        }
      }
      // ::v-deep .swiper-button-next {
      //   position: absolute;
      //   width: 36px;
      //   height: 36px;
      //   right: 0;
      //   top: 40%;
      //   background-image: url('../assets/image/home/right.png');
      //   background-size: 100%;
      // }
      // ::v-deep .swiper-button-prev {
      //   position: absolute;
      //   width: 36px;
      //   height: 36px;
      //   left: 0;
      //   top: 40%;
      //   background-image: url('../assets/image/home/left.png');
      //   background-size: 100%;
      // }
    }
  }
}
.number-list {
  width: 100%;
  height: 552px;
  background: #0f3b34;
  padding: 0 282px;
  padding-top: 108px;
  display: flex;
  justify-content: space-between;
  .num {
    font-family: FZLanTingHeiS-H-GB, FZLanTingHeiS-H-GB;
    font-weight: 400;
    font-size: 36px;
    color: #ffffff;
    line-height: 42px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 12px;
    span {
      font-family: FZLanTingHeiS-H-GB, FZLanTingHeiS-H-GB;
      font-weight: 400;
      font-size: 24px;
      color: #ffffff;
      line-height: 42px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }
  .num-tlt {
    font-family: Microsoft YaHei UI, Microsoft YaHei UI;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
    line-height: 23px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}
.zhengshu {
  width: 1450px;
  // height: 942px;
  background: #ffffff;
  box-shadow: -1px -5px 37px 0px rgba(15, 59, 52, 0.3);
  margin: 0 auto;
  margin-top: -266px;
  padding: 96px 67px 67px;
  .zs-banner {
    width: 100%;
    display: flex;
    .zs-ban {
      width: 715px;
      height: 395px;
      margin-right: 60px;
      object-fit: cover;
    }
    .zs-jieshao {
      max-width: 538px;
      .js-tlt {
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 20px;
        color: rgba(0, 0, 0, 1);
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 32px;
        margin-top: 16px;
      }
      .js-mask {
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 18px;
        color: rgba(61, 72, 93, 1);
        line-height: 28px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .js-btn {
        width: 130px;
        height: 44px;
        background: #0f3b34;
        border-radius: 90px 90px 90px 90px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 44px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }
  .zs-lists {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 58px;
    .zs-tab {
      width: 118px;
      .tab {
        width: 118px;
        height: 40px;
        border-radius: 3px 3px 3px 3px;
        border: 1px solid #0f3b34;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 16px;
        color: #0f3b34;
        line-height: 40px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-bottom: 20px;
        cursor: pointer;
      }
      .tab-act {
        width: 118px;
        height: 40px;
        background: #103c35;
        border-radius: 3px 3px 3px 3px;
        border: 1px solid #0f3b34;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
    }
    .zs-list {
      width: 1201px;
      display: flex;
      // justify-content: space-between;
      overflow: hidden;
      .zs-item {
        width: 280px;
        user-select: none; /* 禁用文本选择 */
        touch-action: pan-y; /* 禁用图片拖动 */
        cursor: pointer;
        flex-shrink: 0;
        margin-right: 20px;
        .zs-pic {
          width: 100%;
          height: 226px;
          user-select: none; /* 禁用图片选择 */
          -webkit-user-drag: none; /* 禁用图片拖动 */
        }
        .zs-title {
          width: 100%;
          height: 38px;
          background: #eaeaea;
          margin-top: 12px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 38px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          user-select: none; /* 禁用文本选择 */
        }
      }
    }
  }
}
.news {
  width: 100%;
  padding: 0 158px 69px 230px;
  margin-top: 78px;
  .new-tlt {
    width: 100%;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI;
    font-weight: 400;
    font-size: 24px;
    color: #333333;
    line-height: 28px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin-bottom: 13px;
  }
  .new-mask {
    width: 100%;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI;
    font-weight: 400;
    font-size: 16px;
    color: #3d485d;
    line-height: 19px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .news-list {
    width: 100%;
    // display: flex;
    // justify-content: space-between;
    margin-top: 37px;
    .new-item {
      // width: 468px;
      height: 468px;
      // background: #002b66;
      border-radius: 15px 15px 15px 15px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      .new-pic {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
        object-fit: cover;

        &:hover {
          transform: scale(1.1);
        }
      }
      .new-title {
        width: 100%;
        height: 76px;
        background: rgba(40, 44, 43, 0.8);
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 28px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .new-tlt2 {
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          line-height: 35px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
        .jiantou {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}

@keyframes rotate-in {
  0% {
    transform: rotateY(-90deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}
@media screen and (max-width: 750px) {
  .hotgoods {
    padding: 0 !important;
    margin-top: 18px !important;
    .hot-tlt {
      display: none !important;
    }
    .goods-list {
      .good-item {
        width: 49% !important;
        height: 632px !important;
        .project {
          height: 150px !important;
          .project-tlt {
            font-size: 56px !important;
            line-height: 66px !important;
          }
          .project-tlt2 {
            font-size: 46px !important;
            line-height: 56px !important;
          }
        }
      }
      .good-item:nth-child(3n) {
        width: 100% !important;
        height: 702px !important;
      }
    }
  }
  .about-good {
    height: auto !important;
    padding: 0 !important;
    .hot-tlt {
      display: none !important;
    }
    .about-list {
      display: block !important;
      height: auto !important;
      .about-gd {
        height: auto !important;
        margin-bottom: 55px !important;
        .gd-tlt {
          .title {
            font-size: 56px !important;
            line-height: 66px !important;
          }
          .tit-mask {
            font-size: 40px !important;
            line-height: 50px !important;
          }
          .zhongxin {
            width: fit-content !important;
            height: 40px !important;
            font-size: 40px !important;
            line-height: 50px !important;
          }
        }
        .gd-btn {
          display: none !important;
        }
      }
      .video-lists {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 56px !important;

        width: 100% !important;
        height: auto !important;
        .video-item {
          width: 100% !important;
          height: 732px !important;

          .video-img {
            height: 80% !important;
          }

          .video-tlt {
            display: flex;
            align-items: center;
            justify-content: space-between;

            height: 20% !important;
            margin: 0 !important;
            padding: 0 30px;
            z-index: 99999;
            .title-tlt {
              font-size: 46px !important;
              line-height: 56px !important;
            }

            .title-detail {
              span {
                font-size: 46px !important;
                line-height: 56px !important;
              }
              img {
                width: 40px !important;
                height: 40px !important;
              }
            }
          }
        }
      }
    }
    .success {
      height: auto !important;
      margin-bottom: 46px !important;

      .success-cont {
        width: 100% !important;
        height: auto !important;
        padding: 30px !important;
        .success-tlt {
          font-size: 60px !important;
          line-height: 66px !important;
        }
        .tlt-masks {
          font-size: 40px !important;
          line-height: 66px !important;
        }
        .success-goods {
          display: flex !important;
          align-items: center !important;
          margin-top: 50px !important;
          .category {
            height: auto !important;
            margin-right: 50px !important;
            font-size: 46px !important;
            line-height: 56px !important;
          }
        }
        .cate-btn {
          display: none !important;
        }
      }

      .case-lists {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 56px !important;

        position: relative !important;
        height: auto !important;
        margin-top: 56px !important;
        margin-bottom: 56px !important;

        width: 100%;
        height: auto;

        .case-item {
          width: 100% !important;
          height: auto !important;
          position: relative;
          cursor: pointer;

          img {
            width: 100% !important;
            height: 100% !important;
          }

          .hovers {
            width: 100%;
            height: 76px;
            background: rgba(40, 44, 43, 0.8);
            position: absolute;
            left: 0;
            bottom: 0;

            display: flex;
            justify-content: center;
            align-items: center;
            padding: 60px 30px !important;

            opacity: 1 !important;

            .hovers-tlt {
              font-family: Microsoft YaHei UI, Microsoft YaHei UI;
              font-weight: 400;
              font-size: 56px !important;
              line-height: 66px !important;
              color: #ffffff;
              text-align: center;
              font-style: normal;
              text-transform: none;
            }
          }

        }
      }

      .cate-goods {
        position: static !important;
        // display: block !important;
        width: 100% !important;
        height: auto !important;
        margin-top: 46px !important;
        .cate-good {
          width: 33% !important;
          height: 600px !important;
          .hovers {
            padding-top: 25% !important;
          }
        }
      }
    }
  }
  .number-list {
    height: 632px !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    padding: 50px 35px !important;
    .num-item {
      width: 33% !important;
      .num {
        font-size: 80px !important;
        line-height: 86px !important;
        text-align: center !important;
        span {
          font-size: 46px !important;
        }
      }
      .num-tlt {
        font-size: 46px !important;
        line-height: 66px !important;
        text-align: center !important;
      }
    }
  }
  .zhengshu {
    margin: 0 !important;
  }
  .zhengshu {
    height: auto !important;
    width: 100% !important;
    margin-top: 56px !important;
    padding-bottom: 46px !important;
    .zs-ban {
      width: 60% !important;
      height: 632px !important;
    }
    .zs-jieshao {
      .js-tlt {
        font-size: 56px !important;
        line-height: 66px !important;
      }
      .js-mask {
        font-size: 46px !important;
        line-height: 56px !important;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .js-btn {
        width: 248px !important;
        height: 78px !important;
        line-height: 78px !important;
        font-size: 46px !important;
      }
    }
    .zs-lists {
      height: auto !important;
      display: block !important;

      .zs-tab {
        width: fit-content !important;
        margin: 0 auto !important;
        display: flex !important;
        margin-bottom: 33px !important;
        .tab {
          width: 300px !important;
          height: 94px !important;
          font-size: 46px !important;
          line-height: 94px !important;
          margin-right: 25px !important;
        }
      }

      .lunbo {
        height: auto !important;
      }

      .zs-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 56px !important;

        width: 100% !important;
        margin-bottom: 46px !important;

        .zs-item {
          width: 100% !important;
          position: relative;
          // height: 832px !important;
          // margin-bottom: 25px !important;

          .zs-pic {
            width: 100% !important;
            height: 100% !important;
          }

          .zs-title {
            position: absolute;
            bottom: 0;

            width: 100%;
            height: auto !important;
            // height: 15% !important;
            padding: 0px 16px;

            background-color: rgba(234, 234, 234, 0.8);

            font-size: 46px !important;
            line-height: 116px !important;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .zs-item:nth-child(2n) {
          margin-right: 0 !important;
        }
      }
    }
  }
  .news {
    padding: 50px 0px !important;
    flex-wrap: wrap !important;

    .new-tlt {
      font-size: 56px !important;
      line-height: 66px !important;
    }
    .new-mask {
      font-size: 42px !important;
      line-height: 66px !important;
    }
    .news-list {
      flex-wrap: wrap !important;
      display: flex !important;
      flex-direction: column !important;

      .new-item {
        width: 100% !important;
        height: auto !important;
        margin-bottom: 26px !important;

        .new-pic {
          width: 100% !important;
          height: 932px !important;
        }

        .new-title {
          display: flex !important;
          justify-content: space-between !important;
          align-items: center !important;

          padding: 60px 30px !important;

          .new-tlt2 {
            font-size: 42px !important;
            // line-height: 66px !important;
          }

          .jiantou {
            width: 90px !important;
            height: 50px !important;
          }
        }
      }
    }
  }
}
</style>
