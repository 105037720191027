<template>
  <div id="app">
    <navs></navs>
    <!-- <keep-alive> -->
    <router-view :key="$route.fullPath" />
    <!-- </keep-alive> -->
    <foot></foot>
  </div>
</template>
<script>
import navs from './components/navs.vue'
import foot from './components/footer.vue'
// import { pageModuleseo } from '@/api/index'
export default {
  name: 'HomeView',
  data() {
    return {
    
    }
  },
  components: {
    navs,
    foot
  },
  computed: {},
  mounted() {},
  created() {},

  methods: {
    // pageModuleseo() {
    //   pageModuleseo({
    //     language: localStorage.getItem('lang')
    //   }).then((res) => {
    //     if (res.code == 200) {
    //       console.log(res)
    //       this.seoData = response.data
    //     }
    //   })
    // }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  min-height: calc(100vh - 50px);
  position: relative;
}
* {
  box-sizing: border-box;
}
</style>
