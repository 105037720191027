<template>
  <div class="rich-text-viewer" :class="{ 'is-fullscreen': isFullscreen }">
    <div
      class="ql-container ql-snow"
      :style="boxBorder ? {} : { border: 'none' }"
    >
      <div class="ql-editor" ref="contentRef"></div>
    </div>
    <!-- <div
      v-if="enableFullscreen"
      class="fullscreen-toggle"
      @click="toggleFullscreen"
    >
      <i :class="isFullscreen ? 'el-icon-close' : 'el-icon-full-screen'"></i>
    </div> -->
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: 'RichTextViewer',
  props: {
    content: {
      type: String,
      required: true
    },
    boxBorder: {
      type: Boolean,
      default: true
    },
    enableFullscreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFullscreen: false
    }
  },
  methods: {
    renderContent(content) {
      if (!content) return ''

      // 创建一个临时容器来解析内容
      const tempContainer = document.createElement('div')
      tempContainer.innerHTML = content

      // 处理所有图片URL
      const imgElements = tempContainer.getElementsByTagName('img')
      for (let i = 0; i < imgElements.length; i++) {
        const img = imgElements[i]
        const src = img.getAttribute('src')
        if (
          src &&
          !src.startsWith('http') &&
          !src.startsWith(process.env.VUE_APP_BASE_API)
        ) {
          img.setAttribute('src', this.$urlImg(src))
        }
      }

      return tempContainer.innerHTML
    },
    updateContent() {
      const contentRef = this.$refs.contentRef
      if (contentRef) {
        contentRef.innerHTML = this.renderContent(this.content)
      }
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen
      if (this.isFullscreen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  },
  mounted() {
    this.updateContent()
  },
  beforeDestroy() {
    // 确保组件销毁时恢复body的overflow
    document.body.style.overflow = ''
  },
  watch: {
    content: {
      handler() {
        this.updateContent()
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.rich-text-viewer {
  position: relative;
  width: 100%;
  height: 100%;

  .ql-editor {
    width: 100%;
    font-family: Itim, Itim;
    font-weight: 400;
    font-size: 22px;
    color: #515151;
    line-height: 44px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  &.is-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;
    z-index: 9999;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .fullscreen-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }

    i {
      font-size: 20px;
      color: #666;
    }
  }
}

@media screen and (max-width: 750px) {
  .rich-text-viewer {
    .ql-editor {
      font-size: 42px;
      line-height: 66px;
    }
  }
}
</style>
