// 中文
export default {
  navs: {
    name: '格拉丹',
    home: '首页',
    hotel: '酒店帐篷',
    Aluminum: '铝合金帐篷',
    Spherical: '球形帐篷',
    Project: '项目规划',
    hall: '3D展厅',
    Case: '项目案例',
    News: '行业资讯',
    know: '了解格拉丹',
    CompanyProfile: '公司简介',
    CompanyIntroduction: '公司介绍',
    CompanyProjectCase: '项目案例',
    CompanyProjectPlanning: '项目规划'
  },
  btn: {
    back: '返回',
  },
  pagination: {
    prev: '上一页',
    next: '下一页'
  },
  home: {
    VIDEO: '视频中心',
    more: '查看更多'
  },
  services: {
    Planning: '规划服务',
    Plancase: '规划案例'
  },
  exhbit: {
    LuxurySeries: '轻奢系列',
    WildLuxurySeries: '野奢系列'
  },
  ProjectCase: {
    Cases: '项目案例',
    Videos: '项目视频'
  },
  country: {
    Europe: '欧洲',
    EuropeList: '葡萄牙、意大利、保加利亚、荷兰、法国、希腊',
    ME: '中东',
    meList: '哈萨克斯坦、沙特、阿联酋、阿曼',
    china: '中国',
    cnList: '中国大陆、香港、台湾',
    sea: '东南亚',
    seaList: '菲律宾、泰国、新加坡、印尼、马来西亚、马尔代夫、韩国',
    Oceania: '大洋洲',
    OceaniaList: '澳大利亚、新西兰',
    na: '北美',
    naList: '墨西哥、美国、加拿大',
    sa: '南美',
    saList: '智利、玻利维亚、巴西、苏里南'
  },
  AboutUs: '格拉丹简介',
  ContactUs: '联系我们',
  Factory: '工厂环境',
  Honors: '荣誉资质',
  GladenTentFactory: '格拉丹帐篷工厂',
  SocialMedia: '媒体中心',
  phoneNumber: '请输入手机号',
  pleaseName: '请输入姓名',
  pleaseCont: '请输入留言内容',
  Viewdetails: '查看详情',
  submit: '提交',
  quotation: '咨询报价',
  parameters: '产品参数',
  Nocontent: '暂无内容',
  source: '来源',
  Release: '发布时间'
}