<!--
    此组件用于简单的轮播图，目前只针对新闻列表每屏3个做了适配
    如果需要更多的自定义，可以自行修改
-->
<template>
  <div class="simple-swiper">
    <swiper :options="swiperOptions" ref="simpleSwiper" v-if="dataList.length">
      <swiper-slide
        v-for="(slide, index) in dataList"
        :key="index"
        @click.native="handleClick(slide)"
        class="slide-item"
      >
        <slot name="slide" :item="slide">
          <!-- Default slot content -->
          <img
            class="slide-pic"
            :src="$urlImg(slide.coverUrl)"
            :alt="slide.title"
          />
          <div class="slide-title">
            <div class="slide-text">{{ slide.title }}</div>
          </div>
        </slot>
      </swiper-slide>
    </swiper>
    <div :class="['swiper-button-next', `swiper-button-next-${swiperId}`]" slot="button-next" v-if="navigation"></div>
    <div :class="['swiper-button-prev', `swiper-button-prev-${swiperId}`]" slot="button-prev" v-if="navigation"></div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
  name: "SimpleSwiper",
  components: {
    swiper,
    swiperSlide
  },
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    baseSpaceBetween: {
      type: Number,
      default: 0
    },
    slidesPerView: {
      type: Number,
      default: 1
    },
    adaptiveSpaceBetween: {
      type: Boolean,
      default: true
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    autoplayDelay: {
      type: Number,
      default: 3000
    },
    navigation: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      swiperId: `swiper-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      swiperOptions: null
    };
  },

  computed: {
    dynamicSpaceBetween() {
      if (!this.adaptiveSpaceBetween) return this.baseSpaceBetween;
      return Math.floor((this.windowWidth / 1920) * this.baseSpaceBetween);
    }
  },

  created() {
    this.initSwiperOptions();
  },

  mounted() {
    if (this.adaptiveSpaceBetween) {
      window.addEventListener('resize', this.handleResize);
    }
  },

  beforeDestroy() {
    if (this.adaptiveSpaceBetween) {
      window.removeEventListener('resize', this.handleResize);
    }
  },

  methods: {
    initSwiperOptions() {
      const _swiperOptions = {
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        on: {
          init: () => {
            this.$nextTick(this.handleResize);
          }
        }
      };

      // 每屏显示的数量
      if (this.slidesPerView) {
        _swiperOptions.slidesPerView = this.slidesPerView;
      }

      // 根据页面宽度自动计算间距
      if (this.adaptiveSpaceBetween && this.baseSpaceBetween) {
        _swiperOptions.spaceBetween = this.dynamicSpaceBetween;
      } else {
        _swiperOptions.spaceBetween = this.baseSpaceBetween;
      }

      // 自动播放
      if (this.autoplay) {
        _swiperOptions.autoplay = {
          delay: this.autoplayDelay, // 时间间隔
          disableOnInteraction: false // 操作后是否停止自动播放
        };
      }

      if (this.navigation) {
        _swiperOptions.navigation = {
          nextEl: `.swiper-button-next-${this.swiperId}`,
          prevEl: `.swiper-button-prev-${this.swiperId}`
        }; 
      }

      if (this.loop) {
        _swiperOptions.loop = true
      } else {
        _swiperOptions.loop = false
      }

      this.swiperOptions = _swiperOptions;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      if (this.$refs.simpleSwiper && this.$refs.simpleSwiper.swiper) {
        if (this.adaptiveSpaceBetween) {
          this.swiperOptions.spaceBetween = this.dynamicSpaceBetween;
          this.$refs.simpleSwiper.swiper.params.spaceBetween = this.dynamicSpaceBetween;
        }
        this.$refs.simpleSwiper.swiper.update();
      }
    },
    handleClick(item) {
      this.$emit('slide-click', item);
    }
  }
};
</script>

<style scoped lang="scss">
.simple-swiper {
  width: 100%;
  position: relative;
}

.slide-item {
  cursor: pointer;
}

.slide-pic {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.slide-title {
  margin-top: 10px;
}

.slide-text {
  font-size: 16px;
  line-height: 1.4;
}

.swiper-button-next {
   width: 36px;
   height: 36px;
   background-image: url('../assets/image/home/right.png');
   background-size: 100%;
   transition: all 0.3s ease;

   &:hover {
    transform: scale(1.5);
   }
 }
.swiper-button-prev {
   width: 36px;
   height: 36px;
   background-image: url('../assets/image/home/left.png');
   background-size: 100%;
   transition: all 0.3s ease;

   &:hover {
    transform: scale(1.5);
   }
 }
</style>
