import axios from "axios";
// import {
//   MessageBox,
//   Message
// } from "element-ui";
// import store from "@/store";


// create an axios instance
const service = axios.create({
  // baseURL: 'http://hnwdgl.gainet.com/prod-api/fe',
  baseURL: 'https://gl.tents-hotel.com/prod-api/',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    // if (store.getters.token) {
    //   config.headers["Authorization"] = getToken();
    // }
    return config;
  },
  (error) => {
    // do something with request error
    // console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(

  (response) => {
    const res = response.data;
    // console.log("response", response);
    // if the custom Code is not 20000, it is judged as an error.
    if (res.code !== 200 && res.code !== 500) {
      // Message({
      //   message: res.msg || "Error",
      //   type: "error",
      //   duration: 5 * 1000,
      // });
      // alert(res.msg || "Error")
      return
      // if (res.Code === 50008 || res.Code === 50012 || res.Code === 50014) {}
      // return Promise.reject(new Error(res.message || "Error"));
    } else if (res.code == 500) {
      return res;
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    // Message({
    //   message: error.message,
    //   type: "error",
    //   duration: 5 * 1000,
    // });
    // alert(error.message || "Error")
    return Promise.reject(error);
  }
);

export default service;
