import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import VueMeta from 'vue-meta';
Vue.prototype.$axios = axios;
// 使用vue-meta插件
Vue.use(VueMeta);
// 全局混入一个占位的 metaInfo
Vue.mixin({
  metaInfo() {
    return this.$root.globalHeadConfig || {};
  },
});

// import {
//   pageModuleseo
// } from '@/api/index'

// import 'swiper/swiper-bundle.css'; // 引入 Swiper 样式
// import VuePdfEmbed from 'vue-pdf-embed';
// Vue.use(VuePdfEmbed);

import 'amfe-flexible'
// import '../flexible'; // 引入自定义的 flexible.js
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css' // 引入Element UI样式
Vue.use(ElementUI);
import './assets/media.css' // 引入全局CSS文件

import RichShow from "@/components/RichShow"
Vue.component('RichShow', RichShow)
import zh from './language/zh'
import en from './language/english'
// 国际化
import VueI18n from 'vue-i18n'
Vue.use(VueI18n) // 通过插件的形式挂载
const messages = {
  zh,
  en
}
const i18n = new VueI18n({
  messages,
  locale: localStorage.getItem('lang') || 'zh', // 语言标识
})
if (!localStorage.getItem('lang')) {
  localStorage.setItem('lang', 'zh')
}
Vue.prototype.$urlImg = function (url) {
  return 'https://gl.tents-hotel.com/prod-api' + url
}
Vue.config.productionTip = false
Vue.prototype.$context_path = "/prod-api";
// await store.dispatch('seo/fetchSeoData')
new Vue({
  data() {
    return {
      globalHeadConfig: {
        title: this.$store.state.seo.seoData ? this.$store.state.seo.seoData.webtitle : '格拉丹帐篷(广州)有限公司',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: this.$store.state.seo.seoData ? this.$store.state.seo.seoData.describe : ''
          },
          {
            vmid: 'keywords',
            name: 'keywords',
            content: this.$store.state.seo.seoData ? this.$store.state.seo.seoData.keywords : ''
          }
        ],
      },
    };
  },
  watch: {
    '$store.state.seo.seoData': {
      handler(newVal) {
        console.log(newVal)
        this.updateGlobalHeadConfig();
      },
      immediate: true, // 立即触发一次监听器
      deep: true
    }
  },
  async created() {
    try {
      await this.$store.dispatch('seo/fetchSeoData');
    } catch (error) {
      console.error('Error fetching SEO data:', error);
    }
  },
  methods: {
    updateGlobalHeadConfig() {
      const seoData = this.$store.state.seo.seoData;
      console.log('seoDataseoData66666', seoData)
      // const seoDataKey = localStorage.getItem('seokey');
      if (seoData) {
        this.globalHeadConfig.title = seoData.webtitle || this.globalHeadConfig.title;
        this.globalHeadConfig.meta[0].content = seoData.keywords || '';
        this.globalHeadConfig.meta[1].content = seoData.describe || '';
      }
    }
  },
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
