// 路由历史记录模块
const history = {
  namespaced: true, // 启用命名空间
  state: {
    routeHistory: []
  },
  getters: {
    hasPreviousRoute: state => state.routeHistory.length > 1,
    previousRoute: state => state.routeHistory[state.routeHistory.length - 2],
    currentRoute: state => state.routeHistory[state.routeHistory.length - 1]
  },
  mutations: {
    PUSH_ROUTE(state, route) {
      // 避免重复记录相同路由
      if (state.routeHistory.length === 0 || 
          state.routeHistory[state.routeHistory.length - 1].fullPath !== route.fullPath) {
        state.routeHistory.push({
          name: route.name,
          path: route.path,
          fullPath: route.fullPath,
          query: route.query,
          params: route.params,
          meta: route.meta
        });
      }
    },
    POP_ROUTE(state) {
      state.routeHistory.pop();
    },
    CLEAR_ROUTE_HISTORY(state) {
      state.routeHistory = [];
    }
  },
  actions: {
    recordRoute({ commit }, route) {
      commit('PUSH_ROUTE', route);
    },
    async goBack({ getters }) {
      return new Promise(resolve => {
        if (getters.hasPreviousRoute) {
          resolve(getters.previousRoute);
        } else {
          resolve(null);
        }
      });
    },
    popRoute({ commit }) {
      commit('POP_ROUTE');
    },
    clearHistory({ commit }) {
      commit('CLEAR_ROUTE_HISTORY');
    }
  }

  
};

export default history;
