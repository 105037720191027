export default {
  navs: {
    name: 'GLADAN',
    home: 'Home',
    hotel: 'Hotel tents',
    Aluminum: 'Aluminum alloy tent',
    Spherical: 'Spherical tent',
    Project: 'Projects',
    hall: 'Exhibition',
    Case: 'Case',
    News: 'News',
    know: 'About Gladan',
    CompanyProfile: 'Company Profile',
    CompanyIntroduction: 'Company Introduction',
    CompanyProjectCase: 'Project Case',
    CompanyProjectPlanning: 'Project Planning'
  },
  btn: {
    back: 'Back',
  },
  pagination: {
    prev: 'prev',
    next: 'next'
  },
  home: {
    VIDEO: 'VIDEO',
    more: 'See more'
  },
  services: {
    Planning: 'Planning Services',
    Plancase: 'Planning Case'
  },
  exhbit: {
    LuxurySeries: 'Luxury Series',
    WildLuxurySeries: 'Wild Luxury Series'
  },
  ProjectCase: {
    Cases: 'Cases',
    Videos: 'Videos'
  },
  country: {
    Europe: 'Europe',
    EuropeList: 'Portugal, Italy, Bulgaria, Netherlands, France, Greece',
    ME: 'ME',
    meList: 'Kazakhstan, Saudi Arabia, United Arab Emirates, Oman',
    china: 'China',
    cnList: 'China’s mainland, Hong Kong, Taiwan',
    sea: 'SEA',
    seaList: 'Philippines, Thailand, Singapore, Indonesia, Malaysia, Maldives, South Korea',
    Oceania: 'Oceania',
    OceaniaList: 'Australia, New Zealand',
    na: 'NA',
    naList: 'Mexico, United States, Canada',
    sa: 'SA',
    saList: 'Chile, Bolivia, Brazil, Suriname'
  },
  AboutUs: 'About Us',
  ContactUs: 'Contact Us',
  Factory: 'Factory Environment',
  Honors: 'Honors',
  GladenTentFactory: 'Gladen Tent Factory',
  SocialMedia: 'Social Media',
  phoneNumber: 'Please enter your phone number',
  pleaseName: 'Please enter your name',
  pleaseCont: 'Please enter the message content',
  Viewdetails: 'View details',
  submit: 'submit',
  quotation: 'Consultation and quotation',
  parameters: 'Product parameters',
  Nocontent: 'No content available at the moment',
  source: 'source',
  Release: 'Release time'
}
