<template>
  <div class="foot">
    <div class="contents">
      <div class="cont-left">
        <div class="left-conts">
          <div
            class="left-tlt"
            v-if="footInfo.FOOT_ONLINE_SERVICE?.moduleInfoMap?.PAGE_PLATE_TITLE"
          >
            {{ footInfo.FOOT_ONLINE_SERVICE?.moduleInfoMap?.PAGE_PLATE_TITLE?.message }}
          </div>
          <div
            class="left-item"
            v-if="footInfo.FOOT_ONLINE_SERVICE?.moduleInfoMap?.FOOT_ONLINE_SERVICE_PHONE"
          >
            <img src="../assets/image/foot/icon.png" alt="" />
            <div class="item">
              {{
                footInfo.FOOT_ONLINE_SERVICE?.moduleInfoMap?.FOOT_ONLINE_SERVICE_PHONE?.message
              }}
            </div>
          </div>
          <!-- <div
            class="left-item"
            v-if="footInfo.FOOT_ONLINE_SERVICE?.moduleInfoMap?.FOOT_ONLINE_SERVICE_PHONE2"
          >
            <img src="../assets/image/foot/icon.png" alt="" />
            <div class="item">
              {{
                footInfo.FOOT_ONLINE_SERVICE?.moduleInfoMap?.FOOT_ONLINE_SERVICE_PHONE2?.message
              }}
            </div>
          </div> -->
          <div
            class="left-item"
            v-if="footInfo.FOOT_ONLINE_SERVICE?.moduleInfoMap?.FOOT_ONLINE_SERVICE_EMAIL"
          >
            <img src="../assets/image/foot/icon2.png" alt="" />
            <div class="item">
              {{
                footInfo.FOOT_ONLINE_SERVICE?.moduleInfoMap?.FOOT_ONLINE_SERVICE_EMAIL?.message
              }}
            </div>
          </div>
          <div
            class="left-item"
            v-if="footInfo.FOOT_ONLINE_SERVICE?.moduleInfoMap?.FOOT_ONLINE_SERVICE_ADDRESS"
          >
            <img src="../assets/image/foot/icon3.png" alt="" />
            <div class="item">
              {{
                footInfo.FOOT_ONLINE_SERVICE?.moduleInfoMap?.FOOT_ONLINE_SERVICE_ADDRESS?.message
              }}
            </div>
          </div>
        </div>
        <div class="right-conts">
          <div class="jianjie">{{ $t('navs.CompanyProfile') }}</div>
          <div class="nav-item" @click="goGongsi">
            {{ $t('navs.CompanyIntroduction') }}
          </div>
          <div class="nav-item" @click="goProjectCase">
            {{ $t('navs.CompanyProjectCase') }}
          </div>
          <div class="nav-item" @click="goProjectPlanning">
            {{ $t('navs.CompanyProjectPlanning') }}
          </div>
          <div
            class="nav-item"
            v-for="item in categoriesList"
            :key="item.id"
            @click="seleNavCate(item)"
          >
            {{ item.name }}
          </div>
          <!-- <div class="nav-item">大型篷房</div> -->
        </div>
      </div>
      <div class="cont-right">
        <div
          class="meiti"
          v-if="footInfo.FOOT_MEDIA_ACCOUNTS?.moduleInfoMap?.PAGE_PLATE_TITLE?.message"
        >
          <div class="mt-tlt">
            {{ footInfo.FOOT_MEDIA_ACCOUNTS?.moduleInfoMap?.PAGE_PLATE_TITLE?.message }}
          </div>
          <div class="mt-list">
            <div
              class="mt-container"
              v-for="(item, index) in filteredMtList"
              :key="index">
              <img
                :src="item.path"
                alt=""
              />
              <div class="mt-code">
                <img class="mtcode" :src="$urlImg(getMtQrCode(item.code))" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="qrcode" v-if="footInfo.FOOT_MEDIA_PLATFORM?.moduleInfoMap?.PAGE_PLATE_TITLE">
          <div class="mt-tlt">
            {{ footInfo.FOOT_MEDIA_PLATFORM?.moduleInfoMap?.PAGE_PLATE_TITLE?.message }}
          </div>
          <img
            class="code"
            :src="
              $urlImg(
                footInfo.FOOT_MEDIA_PLATFORM?.moduleInfoMap?.PAGE_PLATE_CONFIG_CONTENT?.message
              )
            "
            alt=""
          />
          <div class="mask">
            {{ footInfo.FOOT_MEDIA_PLATFORM?.moduleInfoMap?.PAGE_PLATE_DESCRIPTION?.message }}
          </div>
        </div>
      </div>
    </div>
    <div class="bot-cont" v-if="footInfo.FOOT_ICP?.moduleInfoMap">
      <img class="toplogo" src="../assets/image/foot/logo.png" alt="" />
      <div class="bot-xian"></div>
      <div class="bot-mask">
        <!-- Copyright©2023格拉丹集团有限公司 All Right Reserved 版权所有
        粤ICP备2023137316号 -->
        <span>{{ footInfo.FOOT_ICP.moduleInfoMap?.icp?.message }}</span>
        <span @click="openBeian" style="cursor: pointer;">{{
          footInfo.FOOT_ICP?.moduleInfoMap?.policeICP?.message
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { moduleInfo, categoriesNav } from '@/api/index'
export default {
  name: 'HomeView',
  data() {
    return {
      navIdx: 0,
      mtList: [
        {
          path: require('../assets/image/foot/taobao.png'),
          code: 'FOOT_MEDIA_ACCOUNTS_TAOBAO'
        },
        {
          path: require('../assets/image/foot/1688.png'),
          code: 'FOOT_MEDIA_ACCOUNTS_1688'
        },
        {
          path: require('../assets/image/foot/douyin.png'),
          code: 'FOOT_MEDIA_ACCOUNTS_TikTok'
        },
        {
          path: require('../assets/image/foot/mth.png'),
          code: 'FOOT_MEDIA_ACCOUNTS_WE_CHAT_VIDEO'
        },
        {
          path: require('../assets/image/foot/hongshu.png'),
          code: 'FOOT_MEDIA_ACCOUNTS_RED'
        },
        {
          path: require('../assets/image/foot/weixin.png'),
          code: 'FOOT_MEDIA_ACCOUNTS_WE_CHAT'
        }
      ],
      footInfo: {},
      webCode: '',
      categoriesList: [],
      dynamicMargin: 0
    }
  },
  mounted() {},
  created() {
    this.moduleInfo()
    this.categoriesNav()
    // console.log(
    //   'this.$store.state.categoriesList',
    //   this.$store.state.categoriesList
    // )
    // this.categoriesList = this.$store.state.categoriesList
  },

  computed: {
    filteredMtList() {
      return this.mtList.filter(item => this.getMtQrCode(item.code))
    }
  },
  methods: {
    categoriesNav() {
      categoriesNav({
        language: localStorage.getItem('lang')
      }).then((res) => {
        if (res.code == 200) {
          console.log(res)
          this.categoriesList = res.data
          // this.$store.commit('SET_CATEGORIE', this.categoriesList)
        }
      })
    },
    seleNavCate(item) {
      this.$router.push(
        {
          path: '/hotelGoods',
          query: { id: item.id }
        },
        () => {}
      )
    },
    goGongsi() {
      this.$router.push(
        {
          path: '/aboutUs'
          // query: { id: item.id }
        },
        () => {}
      )
    },
    goProjectCase() {
      this.$router.push(
        {
          path: '/ProjectCase'
          // query: { id: item.id }
        },
        () => {}
      )
    },
    goProjectPlanning() {
      this.$router.push(
        {
          path: '/ProjectPlan'
          // query: { id: item.id }
        },
        () => {}
      )
    },
    openBeian() {
      window.open(
        this.footInfo.FOOT_ICP.moduleInfoMap.policeICP.externalLink,
        '_blank',
        'noopener,noreferrer'
      )
    },
    getMtQrCode(code) {
      const matchedItem = Object.entries(this.footInfo.FOOT_MEDIA_ACCOUNTS?.moduleInfoMap).find(([_, value]) => value.webCode === code);
      return matchedItem ? matchedItem[1].message : '';
    },
    moduleInfo() {
      moduleInfo({
        language: localStorage.getItem('lang'),
        moduleCode: 'PAGE_FOOT'
      }).then((res) => {
        if (res.code == 200) {
          const _result = res.data
          for (let i = 0; i < _result.length; i++) {
            const _code = _result[i].moduleCode
            this.$set(this.footInfo, _code, _result[i])
          }
          // this.mtList = this.footInfo.FOOT_MEDIA_ACCOUNTS?.moduleInfo.slice(1)
          // this.webCode =
          //   this.footInfo.FOOT_MEDIA_ACCOUNTS?.moduleInfoMap.FOOT_MEDIA_ACCOUNTS_TAOBAO.message
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.foot {
  width: 100%;
  // height: 434px;
  background: #0e0e12;
  overflow: hidden;
  padding: 42px 0 0 0;
  display: grid;
  grid-template-columns: max-content; /* 列宽度由第一个子元素内容决定 */
  justify-content: center;
  .contents {
    display: flex;
    // justify-content: space-between;

    .cont-left {
      display: flex;
      .left-conts {
        width: 330px;
        overflow: hidden;
        margin-right: 92px;
        .left-tlt {
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: bold;
          font-size: 20px;
          color: #ffffff;
          line-height: 23px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 19px;
        }
        .left-item {
          display: flex;
          align-items: center;
          margin-bottom: 23px;
          img {
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
          .item {
            width: 90%;
            font-family: Plus Jakarta Sans, Plus Jakarta Sans;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            line-height: 16px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            // flex-shrink: 0;
          }
        }
      }
      .right-conts {
        min-width: 90px;
        margin-right: 177px;
        .jianjie {
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: bold;
          font-size: 18px;
          color: #ffffff;
          line-height: 21px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 24px;
        }
        .nav-item {
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          line-height: 19px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 20px;
          cursor: pointer;
        }
      }
    }
    .cont-right {
      display: flex;
      .meiti {
        width: 400px;
        margin-right: 180px;
        // overflow: hidden;
        .mt-tlt {
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: bold;
          font-size: 18px;
          color: #ffffff;
          line-height: 21px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 26px;
        }
        .mt-list {
          width: 100%;
          display: flex;
          justify-content: start;
          gap:10px;
          align-items: center;
          padding: 0;
          img {
            position: relative;
            width: 46px;
            height: 46px;
          }
          img:last-child {
            margin-right: 0;
          }
        }
        .mt-container {
          position: relative;
          cursor: pointer;
        }
        .mt-container:hover .mt-code {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
        .mt-code {
          position: absolute;
          left: -50%;
          width: 105px;
          height: 107px;
          background: #60a297;
          border-radius: 6px 6px 6px 6px;
          margin-top: 18px;
          // margin-left: 24px;
          padding: 9px 8px;
          opacity: 0;
          visibility: hidden;
          transform: translateY(-10px);
          transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
          .mtcode {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
      .qrcode {
        max-width: 140px;
        .mt-tlt {
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: bold;
          font-size: 18px;
          color: #ffffff;
          line-height: 21px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 26px;
          white-space: nowrap;
        }
        .code {
          width: 137px;
          height: 137px;
          margin-bottom: 14px;
        }
        .mask {
          width: 137px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: 400;
          font-size: 16px;
          color: #a4a4a4;
          line-height: 19px;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }
      }
    }
  }
  .bot-cont {
    width: 100%;
    height: auto;
    margin-bottom: 18px;
    .toplogo {
      width: 78px;
      height: 27px;
      display: block;
      margin: 0 auto;
      margin-bottom: 16px;
    }
    .bot-xian {
      width: 100%;
      height: 1px;
      background: #d0d0d0;
      border-radius: 0px 0px 0px 0px;
      opacity: 0.5;
      margin-bottom: 23px;
    }
    .bot-mask {
      font-family: Plus Jakarta Sans, Plus Jakarta Sans;
      font-weight: 400;
      font-size: 14px;
      color: #a4a4a4;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      > :not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .foot {
    grid-template-columns: 1fr;
    padding: 112px;
    .contents {
      display: flex;
      flex-direction: column;

      .cont-left {
        display: flex;
        flex-direction: column;

        .left-conts {
          width: 100% !important;
          margin-right: 0 !important;
        }

        .left-tlt {
          font-size: 56px !important;
          line-height: 66px !important;
        }

        .left-item {
          img {
            width: 46px !important;
            height: 46px !important;
            margin-right: 16px !important;
          }

          .item {
            font-size: 42px !important;
            line-height: 66px !important;
          }
        }

        .right-conts {
          width: 100% !important;
          margin-right: 0 !important;
          margin-top: 56px !important;
        }

        .jianjie {
          font-size: 56px !important;
          line-height: 66px !important;
        }

        .nav-item {
          font-size: 42px !important;
          line-height: 66px !important;
        }
      }
      .cont-right {
        display: flex;
        flex-direction: column;

        margin-top: 56px !important;

        .meiti {
          display: none;
        };

        .qrcode {
          width: 100% !important;
          max-width: 100% !important;
          margin-right: 0 !important;
          .mt-tlt {
            font-size: 56px !important;
            line-height: 66px !important;
          }
          .code {
            width: 580px !important;
            height: 580px !important;
          }
          .mask {
            width: 100% !important;
            font-size: 42px !important;
            line-height: 66px !important;
            text-align: left !important;
          }
        }
      }
    }

    .bot-cont {
      .toplogo {
        width: 312px !important;
        height: 108px !important;
        margin-bottom: 56px !important;
      }
      .bot-xian {
        height: 2px !important;
        margin-bottom: 56px !important;
      }
      .bot-mask {
        display: flex;
        flex-direction: column;
        gap: 16px;

        font-size: 42px !important;
        line-height: 66px !important;

        span {
          margin-left: 0 !important;
        }
      }
    }
  }
}
</style>
