<template>
  <div class="navs">
    <div class="pcdevice">
      <div class="nav-top">
        <div
          class="nav-left"
          v-if="headInfo.HEAD_LEFT?.moduleInfoMap?.COMMON_LOGO?.message"
        >
          <img
            class="toplogo"
            :src="$urlImg(headInfo.HEAD_LEFT?.moduleInfoMap?.COMMON_LOGO?.message)"
            alt=""
          />
          <!-- <div class="logo-name">{{ $t('navs.name') }}</div> -->
        </div>
        <div
          class="nav-right"
          v-if="headInfo.HEAD_RIGHT?.moduleInfoMap?.PAGE_HEAD_AD?.message"
        >
          <div class="right-top">
            <div class="l-tit">
              {{ headInfo.HEAD_RIGHT?.moduleInfoMap?.PAGE_HEAD_AD?.message }}
            </div>
            <!-- <div class="r-tit">研发/设计/生产/销售</div> -->
          </div>
          <div
            class="right-bot"
            v-if="headInfo.HEAD_RIGHT?.moduleInfoMap?.PAGE_HEAD_PHONE?.message"
          >
            <img class="phone" src="../assets/image/phone.png" alt="" />
            <div class="phone-num">
              {{ headInfo.HEAD_RIGHT?.moduleInfoMap?.PAGE_HEAD_PHONE?.message }}
            </div>
          </div>
        </div>
      </div>
      <div class="navs">
        <div class="nav-list">
          <div
            class="nav-item"
            :class="navIdx == 0 ? 'nav-item-act' : ''"
            @click="seleNavHome()"
          >
            {{ $t('navs.home') }}
          </div>
          <div
            class="nav-item"
            v-for="item in categoriesList"
            :key="item.id"
            :class="navIdx == item.id ? 'nav-item-act' : ''"
            @click="seleNavCate(item)"
          >
            {{ item.name }}
          </div>
          <div
            class="nav-item"
            v-for="item in navList"
            :key="item.id"
            :class="navIdx == item.id ? 'nav-item-act' : ''"
            @click="seleNav(item)"
          >
            {{ item.name }}
          </div>
        </div>
        <!-- <div class="language" @click="translate">
          <img class="eng" src="../assets/image/eng.png" alt="" />
          <div class="lang">{{ language }}</div>
        </div> -->
      </div>
    </div>
    <div class="yddevice">
      <div class="nav-info" v-if="isYdShow">
        <i class="el-icon-s-unfold" @click="openYdNav"></i>
        <div class="lago-yd">
          <img
            class="yd-logo"
            :src="$urlImg(headInfo.HEAD_LEFT?.moduleInfoMap?.COMMON_LOGO?.message)"
            alt=""
          />
        </div>
        <div class="yd-language" @click="translate">
          <img class="ydeng" src="../assets/image/ydeng.png" alt="" />
          <div class="yd-engtlt">{{ language }}</div>
        </div>
      </div>
      <div class="ydnav-list" v-if="!isYdShow">
        <div class="list-top">
          <img class="yd-logo2" src="../assets/image/toplogo.png" alt="" />
          <i class="el-icon-close" @click="closeNav"></i>
        </div>
        <div class="list2">
          <div class="list2-item" @click="seleNavHome()">
            {{ $t('navs.home') }}
          </div>
          <div
            class="list2-item"
            v-for="item in categoriesList"
            :key="item.id"
            @click="seleNavCate(item)"
          >
            {{ item.name }}
          </div>
          <div
            class="list2-item"
            v-for="item in navList"
            :key="item.id"
            @click.stop="seleNav(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { categoriesNav, moduleInfo } from '@/api/index'
export default {
  name: 'HomeView',
  data() {
    return {
      language: 'EN',
      isYdShow: true,
      // ydNavList: false,
      navIdx: 0,
      categoriesList: [],
      navList: [
        {
          id: 4,
          name: this.$t('navs.Project'),
          path: '/ProjectPlan'
        },
        {
          id: 5,
          name: this.$t('navs.hall'),
          path: '/ExhibitionHall'
        },
        {
          id: 6,
          name: this.$t('navs.Case'),
          path: '/ProjectCase'
        },
        {
          id: 7,
          name: this.$t('navs.News'),
          path: '/IndustryNews'
        },
        {
          id: 8,
          name: this.$t('navs.know'),
          path: '/aboutUs'
        }
      ],
      headInfo: {}
    }
  },
  mounted() {},
  created() {
    this.$store.commit('SET_NAVLIST', this.navList)
    this.categoriesNav()
    this.moduleInfo()
    if (localStorage.getItem('lang') == 'zh') {
      this.language = 'EN'
    } else {
      this.language = 'ZH'
    }
    const newnavIdx = localStorage.getItem('navIdx')
    if (newnavIdx) {
      this.navIdx = newnavIdx
    }
  },
  watch: {
    $route(to) {
      this.setActiveNavItem(to.path)
    }
  },
  methods: {
    setActiveNavItem(path) {
      // 根据路径找到对应的导航项，并更新navIdx
      // var selectedItem = 0
      console.log('path', path)
      if (path == '/hotelGoods') {
        localStorage.setItem('navIdx', this.navIdx)
      } else {
        const selectedItem = this.navList.find((item) => item.path === path)
        if (selectedItem) {
          this.navIdx = selectedItem.id
          localStorage.setItem('navIdx', this.navIdx) // 更新本地存储
        } else {
          this.navIdx = 0 // 如果没有匹配项，则清除选中状态
        }
      }
    },

    moduleInfo() {
      moduleInfo({
        language: localStorage.getItem('lang'),
        moduleCode: 'PAGE_HEAD'
      }).then((res) => {
        if (res.code == 200) {
          const _result = res.data
          for (let i = 0; i < _result.length; i++) {
            const _code = _result[i].moduleCode
            this.$set(this.headInfo, _code, _result[i])
          }
        }
      })
    },
    categoriesNav() {
      categoriesNav({
        language: localStorage.getItem('lang')
      }).then((res) => {
        if (res.code == 200) {
          console.log(res)
          this.categoriesList = res.data
          this.$store.commit('SET_CATEGORIE', this.categoriesList)
        }
      })
    },
    translate() {
      if (localStorage.getItem('lang') == 'zh') {
        localStorage.setItem('lang', 'en')
        this.language = 'EN'
      } else {
        localStorage.setItem('lang', 'zh')
        this.language = 'ZH'
      }
      this.$i18n.locale = localStorage.getItem('lang', 'zh')
      // this.categoriesNav()
      window.location.reload()
    },
    closeNav() {
      this.isYdShow = true
    },
    openYdNav() {
      this.isYdShow = false
    },
    seleNavCate(item) {
      this.navIdx = item.id
      localStorage.setItem('navIdx', this.navIdx)
      this.isYdShow = true
      this.$router.push(
        {
          path: '/hotelGoods',
          query: { id: item.id }
        },
        () => {}
      )
    },
    seleNavHome() {
      this.navIdx = 0
      localStorage.setItem('navIdx', 0)
      this.isYdShow = true
      this.$router.push(
        {
          path: '/'
        },
        () => {}
      )
    },
    //  this.$store.dispatch('selectNav', item.id)
    seleNav(item) {
      this.navIdx = item.id
      localStorage.setItem('navIdx', this.navIdx)
      this.isYdShow = true
      this.$router.push(
        {
          path: item.path
        },
        () => {}
      )
    }
  }
  // watch: {
  //   $route(to) {
  //     console.log('to11111', to)
  //     const matchingNav = this.navList.find((nav) => nav.path === to.path)
  //     if (matchingNav) {
  //       this.$store.dispatch('selectNav', matchingNav.id)
  //     }
  //   }
  // }
}
</script>

<style scoped lang="scss">
.navs {
  width: 100%;
  height: auto;
  background: #ffffff;
  overflow: hidden;
  .nav-top {
    width: 100%;
    height: 140px;
    background: #ffffff;
    padding: 0 238px;
    // padding-top: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    .nav-left {
      height: 87px;
      display: flex;
      align-items: flex-end;
      .toplogo {
        width: 250px;
        height: 87px;
        margin-right: 19px;
      }
      .logo-name {
        font-family: Microsoft JhengHei UI, Microsoft JhengHei UI;
        font-weight: bold;
        font-size: 40px;
        color: #0f3b34;
        line-height: 47px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
    .nav-right {
      .right-top {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        .l-tit {
          font-family: Microsoft JhengHei UI, Microsoft JhengHei UI;
          font-weight: bold;
          font-size: 18px;
          color: #333333;
          line-height: 21px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-right: 20px;
        }
        .r-tit {
          font-family: Microsoft JhengHei UI, Microsoft JhengHei UI;
          font-weight: bold;
          font-size: 18px;
          color: #0f3b34;
          line-height: 21px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }
      .right-bot {
        display: flex;
        align-items: center;
        .phone {
          width: 30px;
          height: 30px;
          margin-right: 4px;
        }
        .phone-num {
          font-family: Microsoft JhengHei UI, Microsoft JhengHei UI;
          font-weight: bold;
          font-size: 20px;
          color: #333333;
          line-height: 23px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }
    }
  }
  .navs {
    width: 100%;
    height: 99px;
    background: #0f3b34;
    border-radius: 0px 0px 0px 0px;
    padding: 0 12%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    // padding-bottom: 37px;
    .nav-list {
      width: 1306px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .nav-item {
        width: auto;
        height: auto;
        padding: 15px 34px;
        margin-right: 1px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: bold;
        font-size: 20px;
        color: #ffffff;
        line-height: 23px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        cursor: pointer;
        // padding: 10px 0;
        // margin-right: 41px;
        flex-shrink: 0;
      }
      .nav-item-act {
        background: #17342f;
      }
    }
    .language {
      display: flex;
      align-items: center;
      cursor: pointer;
      .eng {
        width: 58px;
        height: 58px;
        margin-right: 11px;
      }
      .lang {
        font-family: Microsoft JhengHei UI, Microsoft JhengHei UI;
        font-weight: bold;
        font-size: 20px;
        color: #ffffff;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }
}
.yddevice {
  // display: block;
  width: 100%;
  height: 138px;
  display: none;
  // padding-top: 22px;
  background: #ffffff;
  padding-bottom: 22px;
  margin-bottom: 12px;
  position: relative;
  z-index: 1000;
  // position: fixed;
  // top: 0;
  // left: 0;
  .nav-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    margin-top: 22px;
    padding: 0 20px;
    ::v-deep .el-icon-s-unfold {
      // width: 36px;
      // height: 36px;
      // display: block;
      font-size: 60px;
    }
    .lago-yd {
      display: flex;
      align-items: flex-end;
      .yd-logo {
        width: 212px;
        height: 74px;
        margin-right: 16px;
      }
      .yd-tlt {
        font-family: Microsoft JhengHei UI, Microsoft JhengHei UI;
        font-weight: bold;
        font-size: 40px;
        color: #0f3b34;
        line-height: 40px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
    .yd-language {
      display: flex;
      align-items: center;
      cursor: pointer;
      .ydeng {
        width: 72px;
        height: 72px;
        margin-right: 11px;
      }
      .yd-engtlt {
        font-family: Microsoft JhengHei UI, Microsoft JhengHei UI;
        font-weight: bold;
        font-size: 46px;
        color: #0f3b34;
        line-height: 46px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }
  .ydnav-list {
    width: 100%;
    height: auto;
    background: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 30px;
    padding-top: 23px;
    .list-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      border-bottom: 1px solid #e6e6e6;
      .yd-logo2 {
        width: 180px;
        height: 70px;
      }
      ::v-deep .el-icon-close {
        font-size: 60px;
      }
    }
    .list2 {
      width: 100%;
      // height: 70px;
      .list2-item {
        width: 100%;
        padding: 36px 0;
        border-bottom: 1px solid #e6e6e6;
        padding-left: 20px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 56px;
        color: #000000;
        line-height: 56px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .pcdevice {
    display: none !important;
  }
  .yddevice {
    display: block !important;
  }
}
</style>
