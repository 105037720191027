import request from "./request";
// 导航
export function categoriesNav(params) {
  return request({
    url: '/fe/product/categories',
    method: "get",
    params
  });
}

// 分类下产品查询
export function productList(params) {
  return request({
    url: '/fe/product/list',
    method: "get",
    params
  });
}

// 产品查询
export function productQuery(params) {
  return request({
    url: '/fe/product/query',
    method: "get",
    params
  });
}

// 获取页面设置
export function moduleInfo(params) {
  return request({
    url: '/fe/pageModule/moduleInfo',
    method: "get",
    params
  });
}

// 关于我们
export function pageModuleModuleInfo(params) {
  return request({
    url: '/fe/pageModule/moduleInfo',
    method: "get",
    params
  });
}

// 焦点图接口
export function bannerInfo(params) {
  return request({
    url: '/fe/banner/info',
    method: "get",
    params
  });
}

//首页内容获取(栏目推荐内容)
export function recommend(params) {
  let catgoryCodes = params.catgoryCode
  return request({
    url: `/fe/cms/${catgoryCodes}/recommend`,
    method: "get",
    params
  });
}


// 首页内容获取-成功案例
export function projectRecommend(params) {
  return request({
    url: '/fe/cms/project_cases_subs/recommend',
    method: "get",
    params
  });
}

// 栏目内容查询
export function cmsList(params) {
  return request({
    url: '/fe/cms/list',
    method: "get",
    params
  });
}

// 获取内容详情
export function cmsQuery(params) {
  return request({
    url: '/fe/cms/query',
    method: "get",
    params
  });
}

// 获取联系我们页面的可选标签项
export function messageTags(params) {
  return request({
    url: '/fe/customer/message/tags',
    method: "get",
    params
  });
}

// 留言
export function messageSubmit(data) {
  return request({
    url: '/fe/customer/message/submit',
    method: "post",
    data
  });
}

// SEO配置
export function pageModuleseo(params) {
  return request({
    url: '/fe/pageModule/seo',
    method: "get",
    params
  });
}